import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { push } from "connected-react-router";

import propTypes from "../../../../constants/propTypes";
import {
  showSnackNotificationAction,
  closeModalDialog,
} from "../../../../actions/layoutActions";
import {
  performDeleteRequest,
  setReload,
} from "../../../../actions/dataComponentActions";
import { processDeleteRequestStatus } from "../../../../utils/dataComponentUtils";
import { getDataComponent } from "../../../../reducers/dataComponentReducer";
import withPORevision from "../../../../withPORevision";
import DeleteSpec from "./DeleteSpec";

export class DeleteSpecContainer extends Component {
  componentDidUpdate({ dataComponent: prevDataComponent }) {
    const {
      dataComponent,
      clientId,
      projectId,
      closeModalDialog,
      showSnackNotificationAction,
      push,
      customNumber,
      backToPO,
      navigate,
      purchaseOrderId,
      dataComponentId,
      setReload,
    } = this.props;

    processDeleteRequestStatus(prevDataComponent, dataComponent, {
      onSuccess: () => {
        closeModalDialog();
        if (!navigate) {
          setReload(dataComponentId, true);
        } else if (backToPO) {
          push(
            `/clients/${clientId}/projects/${projectId}/purchase-orders/${purchaseOrderId}`
          );
        } else {
          push(`/clients/${clientId}/projects/${projectId}/specs`);
        }
        showSnackNotificationAction(
          `${customNumber} has been deleted successfully`
        );
      },
      onError: this.handleDeleteRequestError,
    });
  }

  handleDeleteRequestError = ({ data }) => {
    const { showSnackNotificationAction } = this.props;

    data.errors.map(error => {
      if (error.global) {
        showSnackNotificationAction(error.title);
      }
    });
  };

  handleDelete = () => {
    const {
      specId,
      performDeleteRequest,
      dataComponentId,
      revisionValue,
    } = this.props;
    const revisionIsActive = revisionValue.isActive;
    const params = revisionIsActive
      ? {
          update_po_revision: true,
        }
      : undefined;

    performDeleteRequest(dataComponentId, specId, params);
  };

  render() {
    const { specId, customNumber } = this.props;
    return (
      <DeleteSpec
        specId={specId}
        customNumber={customNumber}
        onDelete={this.handleDelete}
      />
    );
  }
}

DeleteSpecContainer.propTypes = {
  customNumber: PropTypes.string.isRequired,
  specId: PropTypes.string.isRequired,
  purchaseOrderId: PropTypes.string,
  dataComponentId: PropTypes.string.isRequired,
  clientId: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  dataComponent: propTypes.dataComponent.isRequired,
  performDeleteRequest: PropTypes.func.isRequired,
  closeModalDialog: PropTypes.func.isRequired,
  setReload: PropTypes.func.isRequired,
  showSnackNotificationAction: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  navigate: PropTypes.bool,
  backToPO: PropTypes.bool,
  revisionValue: PropTypes.shape({
    isActive: PropTypes.bool,
  }),
};

const mapStateToProps = (state, { dataComponentId }) => {
  const dataComponent = getDataComponent(dataComponentId, state);
  return {
    dataComponent,
  };
};

const mapDispatchToProps = {
  performDeleteRequest,
  closeModalDialog,
  showSnackNotificationAction,
  push,
  setReload,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withPORevision(DeleteSpecContainer, "SpecDetail", "purchaseOrder", true));
