import DataRow from "./DataRow";

export default class Data {
  constructor(invoice) {
    this.merchandise = new DataRow(invoice, "merchandise");
    this.overage = new DataRow(invoice, "overage");
    this.tax = new DataRow(invoice, "tax");
    this.accruedUseTax = new DataRow(invoice, "accruedUseTax");
    this.freightWarehousing = new DataRow(invoice, "freightWarehousing");
    this.install = new DataRow(invoice, "install");
    this.otherCost = new DataRow(invoice, "otherCost");
    this.discount = new DataRow(invoice, "discount");
  }
}
