import React, { Fragment } from "react";

import VendorProducts from "../VendorProducts";
import VendorCertifications from "../VendorCertifications";

const VendorDetail = () => {
  return (
    <Fragment>
      <VendorProducts />
      <VendorCertifications />
    </Fragment>
  );
};

export default VendorDetail;
