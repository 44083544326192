import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _get from "lodash.get";
import AddCircle from "@material-ui/icons/AddCircle";

import {
  openModalDialog,
  showSnackNotificationAction,
} from "../../../../actions/layoutActions";
import {
  initDataComponent,
  performRetrieveListRequest,
  performDeleteRequest,
  setReload,
} from "../../../../actions/dataComponentActions";
import propTypes from "../../../../constants/propTypes";
import {
  getDataComponent,
  getDataComponentFlattenedRequestState,
} from "../../../../reducers/dataComponentReducer";
import { getBluechipResourcesByType } from "../../../../utils/bluechipUtils";
import { Paper } from "../../../mui/core";
import { LoaderContext } from "../../../ui/Loader";
import { useDeleteProcessRequests } from "../../../hooks/useProcessRequest";
import { convertServerErrorToFormikErrors } from "../../../../utils/formValidationUtils";
import withRenderOnPermissionActive from "../../../hocs/withRenderOnPermissionActive";
import { BWGridAPI, GridHeader, GridTable } from "../../../ui/BWGrid";
import UnitOfMeasure from "../../../../models/UnitOfMeasure";

export const unitOfMeasureDataComponentId = "dataComponentUnitOfMeasure";

export const columns = ["name", "description"];

export const columnOptions = {
  name: { title: "Name", filter: true },
  description: { title: "Description", filter: true },
};

const rowMenu = ({ handleDeleteUnitOfMeasure, handleOpenEditModal }) => [
  {
    text: "Edit",
    onClick: handleOpenEditModal,
  },
  {
    text: "Delete",
    onClick: handleDeleteUnitOfMeasure,
  },
];

const actions = onOpenCreateModal => [
  {
    text: "Create Unit of Measure",
    icon: <AddCircle />,
    handler: onOpenCreateModal,
  },
];

export const UnitOfMeasureContainer = ({
  dataComponent,
  flattenedDataComponent,
  loading,
  performDeleteRequest,
  setReload,
  showSnackNotificationAction,
  openModalDialog,
}) => {
  useDeleteProcessRequests(dataComponent, {
    onSuccess: () => {
      showSnackNotificationAction(
        "The Unit of Measure has been deleted successfully"
      );
      setReload(unitOfMeasureDataComponentId, true);
    },
    onError: error => {
      const convertedError = convertServerErrorToFormikErrors(error);
      showSnackNotificationAction(convertedError["unit_of_measures"]);
    },
  });

  const handleOpenCreateModal = useCallback(() => {
    openModalDialog(
      "Create Unit of Measure",
      "CreateUnitOfMeasure",
      {},
      true,
      true
    );
  }, [openModalDialog]);

  const handleOpenEditModal = useCallback(
    unitOfMeasure => {
      openModalDialog(
        "Edit Unit of Measure",
        "EditUnitOfMeasure",
        { unitOfMeasure },
        true,
        true
      );
    },
    [openModalDialog]
  );

  const handleDeleteUnitOfMeasure = useCallback(
    ({ id }) => {
      performDeleteRequest(unitOfMeasureDataComponentId, id);
    },
    [performDeleteRequest]
  );

  return (
    <Paper>
      <LoaderContext.Provider value={loading}>
        <BWGridAPI
          dataComponent={flattenedDataComponent}
          model={UnitOfMeasure}
          defaultSorting={[
            {
              columnName: "name",
              direction: "asc",
            },
          ]}
          apiRoute="unit-of-measures"
          includes={[]}
        >
          <GridHeader
            headerText={`${flattenedDataComponent.totalRows} Units of Measure`}
            actions={actions(handleOpenCreateModal)}
          />
          <GridTable
            columns={columns}
            columnOptions={columnOptions}
            rowMenu={rowMenu({
              handleDeleteUnitOfMeasure,
              handleOpenEditModal,
            })}
          />
        </BWGridAPI>
      </LoaderContext.Provider>
    </Paper>
  );
};

UnitOfMeasureContainer.propTypes = {
  dataComponent: propTypes.dataComponent,
  flattenedDataComponent: propTypes.dataComponent,
  loading: PropTypes.bool,
  openModalDialog: PropTypes.func.isRequired,
  performDeleteRequest: PropTypes.func.isRequired,
  setReload: PropTypes.func.isRequired,
  showSnackNotificationAction: PropTypes.func.isRequired,
};

export const mapStateToProps = state => {
  const flattenedDataComponent = getDataComponentFlattenedRequestState(
    unitOfMeasureDataComponentId,
    state
  );

  const dataComponent = getDataComponent(unitOfMeasureDataComponentId, state);

  const requirements =
    getBluechipResourcesByType(unitOfMeasureDataComponentId, state) || [];
  return {
    dataComponent,
    flattenedDataComponent,
    loading: _get(flattenedDataComponent, "loading") || !requirements,
  };
};

export const mapDispatchToProps = {
  initDataComponent,
  performRetrieveListRequest,
  performDeleteRequest,
  setReload,
  showSnackNotificationAction,
  openModalDialog,
};

export default withRenderOnPermissionActive(
  connect(mapStateToProps, mapDispatchToProps)(UnitOfMeasureContainer),
  ["general-settings-unit-of-measures"]
);
