import React, { Component } from "react";
import { connect } from "react-redux";
import _capitalize from "lodash/capitalize";
import _isEmpty from "lodash/isEmpty";
import _isNil from "lodash/isNil";
import _merge from "lodash/merge";

import { LoaderContext } from "../../ui/Loader";
import PurchaseOrderDetailPage from "./PurchaseOrderDetailPage";
import { checkHasContactsByType } from "./utils";
import withPORevision from "../../../withPORevision";
import User from "../../../models/User";

import { ContentWrapper } from "./components";
import PODetailsPropTypes from "./propTypes";

import { processUpdateRequestStatus } from "../../../utils/dataComponentUtils";
import {
  mapDispatchToProps,
  mapStateToProps,
  dataComponentId,
} from "./connect";
import {
  hasAllRequiredFieldsForTransition,
  poRequiredRevision,
  commonIgnoredStatuses,
} from "./utils";
import { specShipmentsDataComponentId } from "../../../actions/purchaseOrderSpecsActions";
import { generateValues } from "../Notes/NoteCreate/AssignToSelect/AssignToSelectContainer";
import { initSpeShipmentDataComponents } from "../ProjectDetail/Shipments/SpecShipments/SpecShipmentsContainer";

function loadPO({ fetchPurchaseOrder, purchaseOrderId, projectId }, prevPOId) {
  if (!_isNil(prevPOId) && prevPOId === purchaseOrderId) return;
  fetchPurchaseOrder(purchaseOrderId, projectId);
}

export const buildOnError = showSnackNotificationAction => ({ data }) => {
  const error = Array.isArray(data?.errors)
    ? data?.errors[0]?.title
    : data?.errors;
  if (error) {
    showSnackNotificationAction(error);
  }
};

const handleUpdate = ({
  prevDataComponent,
  dataComponent,
  purchaseOrderInstance,
  purchaseOrderId,
  clientId,
  prevRevisionStatus,
  showSnackNotificationAction,
  push,
  setReload,
  clearResources,
}) => {
  processUpdateRequestStatus(prevDataComponent, dataComponent, {
    onSuccess: () => {
      if (
        purchaseOrderInstance.status == "Issued" &&
        (!purchaseOrderInstance.revisionStatus ||
          prevRevisionStatus !== purchaseOrderInstance.revisionStatus)
      ) {
        clearResources(["shipments"]);
        setReload(specShipmentsDataComponentId, true);
        setReload(`Expediting-Shipments-${purchaseOrderId}`, true);
        setReload(`Expediting-Spec-Shipments-${purchaseOrderId}`, true);
      }
      if (purchaseOrderInstance.status === "Canceled") {
        push(
          `/clients/${clientId}/projects/${purchaseOrderInstance.projectId}/expediting/purchase-orders`
        );
      }
    },
    onError: buildOnError(showSnackNotificationAction),
  });
};

export class PurchaseOrderDetail extends Component {
  constructor(props) {
    super(props);
    this.prevRevisionStatusRef = React.createRef();
  }

  componentDidMount() {
    const {
      purchaseOrderId,
      initDataComponent,
      fetchPurchaseOrder,
      fetchPurchaseOrderContacts,
      performRetrieveListRequest,
      populateCountryResources,
      setHeaderTitle,
      setAutoSaveComponentId,
      projectId,
    } = this.props;

    initSpeShipmentDataComponents(initDataComponent, purchaseOrderId);
    loadPO({ fetchPurchaseOrder, purchaseOrderId, projectId });
    fetchPurchaseOrderContacts(purchaseOrderId);
    initDataComponent("select-users", User, [], "users", undefined, "v2");
    performRetrieveListRequest("select-users", {
      sort: [{ columnName: "name", direction: "asc" }],
      pageSize: -1,
      fields: ["users.id", "name"],
    });
    setHeaderTitle("PO Detail");
    setAutoSaveComponentId(dataComponentId);

    populateCountryResources();
  }

  componentDidUpdate({
    dataComponent: prevDataComponent,
    purchaseOrderId: prevPurchaseOrderId,
    purchaseOrderInstance: prevPurchaseOrderInstance,
  }) {
    const {
      dataComponent,
      push,
      purchaseOrderInstance,
      clientId,
      fetchPurchaseOrder,
      purchaseOrderId,
      fetchPurchaseOrderContacts,
      projectId,
      showSnackNotificationAction,
      setReload,
      clearResources,
    } = this.props;

    if (purchaseOrderId != prevPurchaseOrderId) {
      loadPO({ fetchPurchaseOrder, purchaseOrderId, projectId });
      fetchPurchaseOrderContacts(purchaseOrderId);
    }

    handleUpdate({
      prevDataComponent,
      dataComponent,
      purchaseOrderInstance,
      purchaseOrderId,
      clientId,
      prevRevisionStatus: this.prevRevisionStatusRef.current,
      showSnackNotificationAction,
      push,
      setReload,
      clearResources,
    });

    if (
      purchaseOrderInstance &&
      prevPurchaseOrderInstance &&
      prevPurchaseOrderInstance.revisionStatus !==
        purchaseOrderInstance.revisionStatus
    ) {
      this.prevRevisionStatusRef.current =
        prevPurchaseOrderInstance.revisionStatus;
    }
  }

  componentWillUnmount() {
    this.props.setHeaderTitle(null);
  }

  openSubmittalsManagerModal = () => {
    const { purchaseOrderId, openModalDialog } = this.props;

    openModalDialog(
      false,
      "SubmittalsManager",
      {
        purchaseOrderId,
      },
      true,
      true,
      {
        layoutType: "wide",
        freezeBackground: true,
      }
    );
  };

  handleDownloadPDF = (params = {}) => {
    const { openModalDialog, purchaseOrderInstance } = this.props;
    openModalDialog(
      "Download PO PDF",
      "DownloadPurchaseOrderPDF",
      {
        format: "pdf",
        purchaseOrderId: purchaseOrderInstance && purchaseOrderInstance.id,
        templateName: "purchase-order-detail",
        ...params,
      },
      true,
      true
    );
  };

  handleOpenNotesModal = () => {
    const { purchaseOrderInstance, openNotesModal } = this.props;
    openNotesModal(
      {
        projectId: purchaseOrderInstance.projectId,
        assignTo: generateValues([purchaseOrderInstance]),
      },
      {
        search: `"PO#${purchaseOrderInstance.number}"`,
      }
    );
  };

  handleOpenApprovalManagerClick = () => {
    const { purchaseOrderInstance, openModalDialog } = this.props;
    openModalDialog(
      "Approval Manager",
      "ApprovalManager",
      { purchaseOrderId: purchaseOrderInstance.id },
      false,
      true,
      { components: { Content: ContentWrapper }, width: 720 }
    );
  };

  validateCurrentRevision = (purchaseOrderInstance, formikProps, poValues) => {
    if (poRequiredRevision(purchaseOrderInstance, poValues)) {
      const currentRevision = purchaseOrderInstance.currentRevision;
      const errors = ["notes", "type"].reduce((errors, attribute) => {
        if (!currentRevision[attribute]) {
          return {
            ...errors,
            [`currentRevision.${attribute}`]: `${_capitalize(
              attribute
            )} is required`,
          };
        }
        return errors;
      }, {});
      if (Object.keys(errors).length === 0) return;

      formikProps.setFieldValue(
        "status",
        formikProps.initialValues.status,
        false
      );
      formikProps.setSubmitting(false);
      formikProps.setErrors({ ...formikProps.errors, ...errors });
      return errors;
    }
  };

  patchPo = ({ poContacts, ...po }, formikProps) => {
    const { purchaseOrderInstance, purchaseOrderContacts } = this.props;
    const hasFreightContacts = checkHasContactsByType(
      purchaseOrderContacts,
      "freight"
    );
    const hasVendorContacts = checkHasContactsByType(
      purchaseOrderContacts,
      "vendor"
    );
    const hasDesignerContacts = checkHasContactsByType(
      purchaseOrderContacts,
      "vendor"
    );

    if (
      _isEmpty(po) ||
      !_isEmpty(
        this.validateCurrentRevision(purchaseOrderInstance, formikProps, po)
      )
    )
      return;

    if (
      !hasAllRequiredFieldsForTransition(
        {
          ...formikProps.values,
          hasFreightContacts,
          hasVendorContacts,
          hasDesignerContacts,
        },
        purchaseOrderInstance,
        commonIgnoredStatuses,
        formikProps
      )
    )
      return;

    const { performUpdateRequest, purchaseOrderId, revisionValue } = this.props;
    let revisionIsActive = revisionValue.isActive;
    if (po.currentRevision) {
      po.currentRevision.id = purchaseOrderInstance.currentRevisionId;
    }

    performUpdateRequest(
      dataComponentId,
      purchaseOrderId,
      po,
      _merge(
        {
          modifiers: [
            "withOnlyNumber",
            "withCancellable",
            "withNeedsFollowUp",
            "withRevised",
            "withName",
          ],
        },
        revisionIsActive
          ? {
              update_po_revision: true,
            }
          : {}
      )
    );
  };

  handleRevert = ({ revisionId, ...additionalParams } = {}) => {
    const { revertFromRevision, purchaseOrderInstance } = this.props;
    revertFromRevision(
      purchaseOrderInstance.id,
      revisionId || purchaseOrderInstance.currentRevisionId,
      additionalParams
    );
  };

  handleCancel = () => {
    const {
      purchaseOrderInstance: {
        projectId,
        id,
        number: poNumber,
        vendor,
        status,
      },
      clientId,
      openModalDialog,
    } = this.props;
    const title = `${poNumber} ${vendor.name}`;

    openModalDialog(
      ["Cancel Purchase Order", title],
      "CancelPO",
      {
        dataComponentId,
        projectId,
        id,
        clientId,
        title,
        status,
      },
      false
    );
  };

  render() {
    const {
      purchaseOrderInstance,
      purchaseOrderContacts,
      clientId,
      purchaseOrderId,
      loading,
      nextPOId,
      lastPOId,
      projectId,
      openModalDialog,
      closeModalDialog,
    } = this.props;

    return (
      <LoaderContext.Provider value={{ loading }}>
        <PurchaseOrderDetailPage
          clientId={clientId}
          purchaseOrderId={purchaseOrderId}
          purchaseOrder={purchaseOrderInstance || {}}
          purchaseOrderContacts={purchaseOrderContacts || []}
          openSubmittalsManagerModal={this.openSubmittalsManagerModal}
          handleDownloadPDF={this.handleDownloadPDF}
          onOpenApprovalManagerClick={this.handleOpenApprovalManagerClick}
          onOpenNotesModal={this.handleOpenNotesModal}
          openModalDialog={openModalDialog}
          closeModalDialog={closeModalDialog}
          onSubmit={this.patchPo}
          onRevert={this.handleRevert}
          onCancel={this.handleCancel}
          nextPOId={nextPOId}
          lastPOId={lastPOId}
          projectId={projectId}
          loading={loading}
        />
      </LoaderContext.Provider>
    );
  }
}

PurchaseOrderDetail.propTypes = PODetailsPropTypes;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withPORevision(PurchaseOrderDetail, dataComponentId));
