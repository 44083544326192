import React, { Fragment } from "react";

import useUserRole from "../../../hooks/useUserRole";
import { roleCan } from "../../../../utils/roleUtils";

import UpdatePassword from "../UpdatePassword";
import OfficesTable from "../OfficesTable";
import Teams from "../Teams";
import UserRolesTable from "../UserRolesTable";
import Scopes from "./Scopes";

const Administrative = () => {
  const role = useUserRole();

  return (
    <Fragment>
      <OfficesTable />
      {roleCan(role, ["general-settings-reset-password"]) ? (
        <UpdatePassword />
      ) : null}
      {roleCan(role, "manage-teams") ? <Teams /> : null}
      <UserRolesTable />
      <Scopes />
    </Fragment>
  );
};

export default Administrative;
