import { appPageBuilder, redirectTo } from "./utils";
import Settings from "../components/pages/Settings";

import Administrative from "../components/pages/Settings/Administrative";
import ProjectDetail from "../components/pages/Settings/ProjectDetail/ProjectDetail";
import Purchasing from "../components/pages/Settings/Purchasing/Purchasing";
import Reporting from "../components/pages/Settings/Reporting";
import TermsTab from "../components/pages/Settings/Terms/Terms";
import VendorDetail from "../components/pages/Settings/VendorDetail/VendorDetail";

const AppSettings = appPageBuilder(Settings, "Settings");

export default [
  {
    path: "/settings",
    component: AppSettings,
    exact: false,
    routers: [
      {
        path: "/settings/administrative",
        component: Administrative,
        exact: true,
      },
      {
        path: "/settings/project-detail",
        component: ProjectDetail,
        exact: true,
      },
      {
        path: "/settings/purchasing",
        component: Purchasing,
        exact: true,
      },
      {
        path: "/settings/reporting",
        component: Reporting,
        exact: true,
      },
      {
        path: "/settings/terms",
        component: TermsTab,
        exact: true,
      },
      {
        path: "/settings/vendor-detail",
        component: VendorDetail,
        exact: true,
      },
      //default
      {
        path: "/settings",
        component: redirectTo("administrative"),
        exact: true,
      },
    ],
  },
];
