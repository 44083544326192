import React, { useMemo } from "react";
import PropTypes from "prop-types";
import AddCircle from "@material-ui/icons/AddCircle";
import pluralize from "pluralize";
import styled from "styled-components";

import { Content } from "../../layout/Content";
import MUIPaper from "../../mui/core/Paper";
import Client from "../../../models/Client";
import { useHeaderComponent } from "../../ui/BWGrid/GridHeader";
import GridAPI from "../../ui/Grid/GridAPI";
import { buildTextTypeProvider } from "../../ui/Grid/filterDataTypeProviders";
import { RowMenuProvider } from "../../ui/Grid/utilComponents";
import { NameTypeProvider, FavoriteTypeProvider } from "./dataTypeProviders";
import { StyledCell } from "../../ui/Grid/utilComponents/tableComponents/Cell";

const Paper = styled(MUIPaper)`
  ${StyledCell} {
    &:first-child {
      padding-left: 8px;
    }
  }
`;

const filterNameOperator = ({ value }) => {
  return {
    $or: [
      {
        name: {
          $ilike: `%${value}%`,
        },
      },
      {
        "entities.name": {
          $ilike: `%${value}%`,
        },
      },
    ],
  };
};

const ClientsPage = ({
  dataComponent,
  handleAddClient,
  handleRowClick,
  handleEditClient,
  handleDeleteClient,
}) => {
  const filteringDataTypeProviders = useMemo(
    () => [buildTextTypeProvider(["name"])],
    []
  );

  const getRowMenu = row => {
    return [
      { text: "Edit", onClick: () => handleEditClient(row.id) },
      { separator: true },
      {
        text: "Delete",
        onClick: () => handleDeleteClient(row),
      },
    ];
  };

  const rowProps = useMemo(
    () => ({
      onClick: handleRowClick,
      getRowTitle: ({ name }) => `Press to go to ${name} details`,
    }),
    [handleRowClick]
  );

  return (
    <Content>
      <Paper>
        <GridAPI
          apiRoute="clients"
          model={Client}
          includes={["location"]}
          defaultSorting={[{ columnName: "name", direction: "asc" }]}
          apiFilters={{ params: { sortByFavorite: true } }}
          dataComponent={dataComponent}
          rowProps={rowProps}
          columns={[
            {
              name: "isFavorite",
              title: " ",
              width: "64px",
            },
            {
              type: "rowMenu",
              getCellValue: getRowMenu,
              width: "64px",
            },
            {
              name: "name",
              bold: true,
              filter: "text",
              scope: "row",
              filterOptions: { operator: filterNameOperator },
            },
            { name: "location.country", title: "Country" },
            { name: "cleanWebsite", title: "Website" },
          ]}
          columnExtensions={[
            {
              columnName: "isFavorite",
              width: "48px",
              sortingEnabled: false,
            },
            {
              columnName: "name",
              width: "25vh",
            },
            {
              columnName: "location.country",
              width: "15vh",
            },
            {
              columnName: "cleanWebsite",
              align: "right",
            },
          ]}
          tableComponents={{
            GridHeader: useHeaderComponent({
              headerText: pluralize("Client", dataComponent.totalRows, true),
              actions: [
                {
                  text: "ADD CLIENT",
                  icon: <AddCircle />,
                  handler: handleAddClient,
                },
              ],
            }),
          }}
          dataTypeProviders={[
            NameTypeProvider,
            FavoriteTypeProvider,
            RowMenuProvider,
          ]}
          filteringDataTypeProviders={filteringDataTypeProviders}
        />
      </Paper>
    </Content>
  );
};

ClientsPage.propTypes = {
  dataComponent: PropTypes.object.isRequired,
  handleRowClick: PropTypes.func.isRequired,
  handleAddClient: PropTypes.func.isRequired,
  handleEditClient: PropTypes.func.isRequired,
  handleDeleteClient: PropTypes.func.isRequired,
};

export default ClientsPage;
