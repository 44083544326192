import React, { Fragment } from "react";

import BrandsTable from "../BrandsTable";
import ProjectService from "../ProjectService";
import ProjectType from "../ProjectType";

const ProjectDetail = () => {
  return (
    <Fragment>
      <BrandsTable />
      <ProjectService />
      <ProjectType />
    </Fragment>
  );
};

export default ProjectDetail;
