import React, { Fragment } from "react";

import Templates from "../Templates";
import RequirementType from "../RequirementType";
import UnitOfMeasure from "../UnitOfMeasure";

const Purchasing = () => {
  return (
    <Fragment>
      <RequirementType />
      <Templates />
      <UnitOfMeasure />
    </Fragment>
  );
};

export default Purchasing;
