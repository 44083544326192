import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import map from "lodash/map";

import { GridLocalSection } from "../../../layout/GridLocalSection";
import {
  closeModalDialog,
  showSnackNotificationAction,
} from "../../../../actions/layoutActions";
import Paper from "../../../mui/core/Paper";
import { ActionButtons } from "../../../../components/forms";
import propTypes from "../../../../constants/propTypes";
import * as RequestTypes from "../../../../constants/RequestTypes";
import {
  performDeleteRequest,
  setReload,
} from "../../../../actions/dataComponentActions";

import { getDataComponent } from "../../../../reducers/dataComponentReducer";
import { processDeleteRequestStatus } from "../../../../utils/dataComponentUtils";
import { getDataComponentFlattenedRequestState } from "../../../../reducers/dataComponentReducer";
import { getBluechipResources } from "../../../../utils/bluechipUtils";

import { dataComponentId } from "../SpecsContainer";
import useListSpecDetailComs, {
  deleteSpecDetailComsDataComponentId,
} from "./useListSpecDetailComs";

const Title = styled.div`
  padding: 24px 24px 10px 24px;
`;

const StyledPaper = styled(Paper)`
  margin-bottom: 0px;

  ${Paper} {
    box-shadow: none;
    overflow-y: auto;
  }
`;

const Observation = styled.div`
  font-style: italic;
  padding: 0 0 24px 24px;
`;

export const getColumnOptions = comSpecDetailIdSet => {
  return {
    customNumber: {
      bold: true,
      title: "Spec Number",
      render: function customNumberRender(spec) {
        return (
          <b>
            {comSpecDetailIdSet.has(spec.id) ? (
              <i>
                {spec.customNumber}
                <sup>*</sup>
              </i>
            ) : (
              spec.customNumber
            )}
          </b>
        );
      },
    },
    "specCategory.name": { title: "Category" },
    "area.name": { title: "Area", fill: true },
  };
};

const processRequestResponse = (
  prevDataComponent,
  dataComponent,
  closeModalDialog,
  setReload,
  showSnackNotificationAction
) => () => {
  processDeleteRequestStatus(prevDataComponent, dataComponent, {
    onSuccess: () => {
      closeModalDialog();
      setReload(dataComponentId, true);
    },
    onError: ({ data: { errors } }) => {
      errors.forEach(error => {
        if (error.global) {
          showSnackNotificationAction(error.title);
        }
      });
    },
  });
};

const BulkDeleteConfirmationContainer = ({
  dataComponent,
  closeModalDialog,
  setReload,
  performDeleteRequest,
  selectedIds,
  specs,
  showSnackNotificationAction,
}) => {
  const [prevDataComponent, setPrevDataComponent] = useState(dataComponent);

  useEffect(() => {
    setPrevDataComponent(dataComponent);
  }, [dataComponent]);

  useEffect(
    processRequestResponse(
      prevDataComponent,
      dataComponent,
      closeModalDialog,
      setReload,
      showSnackNotificationAction
    )
  );

  const handleDeleteList = useCallback(
    () => performDeleteRequest(dataComponentId, selectedIds),
    [performDeleteRequest, selectedIds]
  );

  const specIdsString = map(specs, "id").join(",");
  const { comSpecDetailIdSet } = useListSpecDetailComs(specIdsString);

  return (
    <StyledPaper>
      <Title>Do you want to delete the following specs?</Title>
      <GridLocalSection
        id="delete-bulk-specs"
        columnsOptions={getColumnOptions(comSpecDetailIdSet)}
        rows={specs}
        columns={[
          "customNumber",
          "description",
          "specCategory.name",
          "area.name",
        ]}
        gridConfig={{ pageSize: 0, totalRows: specs.length }}
      />
      {Boolean(comSpecDetailIdSet.size) && (
        <Observation>
          <sup>*</sup>COM Spec cannot be deleted as it is linked to other specs.
        </Observation>
      )}
      <ActionButtons
        onSend={handleDeleteList}
        sendButtonText="Delete"
        listeners={[deleteSpecDetailComsDataComponentId]}
        additionalProps={{
          send: {
            disabled: Boolean(comSpecDetailIdSet.size),
          },
        }}
        isModal
      />
    </StyledPaper>
  );
};

BulkDeleteConfirmationContainer.propTypes = {
  specs: PropTypes.arrayOf(propTypes.spec),
  dataComponent: propTypes.dataComponent,
  closeModalDialog: PropTypes.func.isRequired,
  performDeleteRequest: PropTypes.func.isRequired,
  selectedIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  setReload: PropTypes.func.isRequired,
  showSnackNotificationAction: PropTypes.func.isRequired,
};

export const mapDispatchToProps = {
  closeModalDialog,
  performDeleteRequest,
  setReload,
  showSnackNotificationAction,
};

const mapStateToProps = state => {
  const dataComponent = getDataComponent(dataComponentId, state);

  const requestState = getDataComponentFlattenedRequestState(
    dataComponentId,
    state
  );
  const selectedIds = dataComponent.requestState[RequestTypes.LIST].selectedIds;
  const specs = getBluechipResources(requestState, state, selectedIds) || [];
  return {
    dataComponent,
    selectedIds,
    specs,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BulkDeleteConfirmationContainer);
