import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { push } from "connected-react-router";
import PropTypes from "prop-types";

import EntitiesPage from "./EntitiesPage";
import {
  initDataComponent,
  performUpdateRequest,
  setReload,
} from "../../../actions/dataComponentActions";
import {
  getDataComponent,
  getDataComponentFlattenedRequestState,
} from "../../../reducers/dataComponentReducer";
import {
  closeModalDialog,
  openModalDialog,
} from "../../../actions/layoutActions";
import Entity from "../../../models/Entity";
import { updateTermsDataComponentId } from "../Settings/Terms/GlobalTerms/BulkUpdateTerms/BulkUpdateTerms";

export class EntitiesContainer extends Component {
  static propTypes = {
    clientId: PropTypes.string,
    dataComponent: PropTypes.object.isRequired,
    openModalDialog: PropTypes.func,
    refreshEntitiesDataComponent: PropTypes.func,
    push: PropTypes.func,
    isModalOpen: PropTypes.bool,
  };

  handleRowClick = ({ id }) => {
    const { clientId, push } = this.props;
    push(`/clients/${clientId}/entities/${id}`);
  };

  render() {
    return (
      <EntitiesPage
        {...this.props}
        model={Entity}
        includes={["client", "location"]}
        apiRoute="entities"
        onRowClick={this.handleRowClick}
      />
    );
  }
}

export const mapStateToProps = (state, ownProps) => ({
  clientId: ownProps.match.params.clientId,
  dataComponent: getDataComponentFlattenedRequestState("EntitiesGrid", state),
  updateTermsDataComponent: getDataComponent(updateTermsDataComponentId, state),
  isModalOpen: state.layout.modalDialog.isOpen,
});

export const mapDispatchToProps = {
  openModalDialog,
  push,
  refreshEntitiesDataComponent: () => setReload("EntitiesGrid", true),
  initDataComponent,
  performUpdateRequest,
  closeModalDialog,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EntitiesContainer)
);
