import React, { memo, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import SettingsPage from "./SettingsPage";
import { roleCan } from "../../../utils/roleUtils";
import propTypes from "../../../constants/propTypes";

const SettingsContainer = ({ route, match, history, role }) => {
  useEffect(() => {
    if (!roleCan(role, ["navigation-settings", "access-to-settings-section"])) {
      history.push("/dashboard");
    }
  }, [history, role]);

  return <SettingsPage route={route} match={match} history={history} />;
};

SettingsContainer.propTypes = {
  role: propTypes.userRole,
  route: propTypes.route,
  match: propTypes.matchRouter,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

const mapStateToProps = ({ auth: { role } }) => {
  return {
    role,
  };
};

export default connect(mapStateToProps, null)(memo(SettingsContainer));
