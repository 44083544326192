import DataRow from "../DataRow";

describe("DataRow", () => {
  describe("freightWarehousing", () => {
    it("using whole tolerance: returns correct notProcessed", () => {
      const invoice = {
        freightWarehousingMaximum: 100,
        freightWarehousingAssetsPaid: 175,
        freightWarehousingDepositsPaid: 100,
        freightWarehousingOtherAssetsPaid: 0,
        freightWarehousingOtherDepositsPaid: 0,
      };

      const dataRow = new DataRow(invoice, "freightWarehousing");

      expect(dataRow.assetsPaid).toBe(175);
      expect(dataRow.assetsToDate).toBe(175);
      expect(dataRow.depositsPaid).toBe(100);
      expect(dataRow.depositsToDate).toBe(100);
      expect(dataRow.rawNotProcessed).toBe(-175);
      expect(dataRow.notProcessed).toBe(0);

      expect(dataRow.notProcessedAssetsPaid).toBe(175);
      expect(dataRow.notProcessedDepositsPaid).toBe(100);
    });

    it("using partial tolerance: returns correct notProcessed", () => {
      const invoice = {
        freightWarehousingMaximum: 100,
        freightWarehousingAssetsPaid: 75,
        freightWarehousingDepositsPaid: 100,
        freightWarehousingOtherAssetsPaid: 0,
        freightWarehousingOtherDepositsPaid: 0,
      };

      const dataRow = new DataRow(invoice, "freightWarehousing");

      expect(dataRow.assetsPaid).toBe(75);
      expect(dataRow.assetsToDate).toBe(75);
      expect(dataRow.depositsPaid).toBe(100);
      expect(dataRow.depositsToDate).toBe(100);
      expect(dataRow.rawNotProcessed).toBe(-75);
      expect(dataRow.notProcessed).toBe(0);

      expect(dataRow.notProcessedAssetsPaid).toBe(175);
      expect(dataRow.notProcessedDepositsPaid).toBe(200);
    });

    it("using no tolerance: returns correct notProcessed", () => {
      const invoice = {
        freightWarehousingMaximum: 100,
        freightWarehousingAssetsPaid: 0,
        freightWarehousingDepositsPaid: 100,
        freightWarehousingOtherAssetsPaid: 0,
        freightWarehousingOtherDepositsPaid: 0,
      };

      const dataRow = new DataRow(invoice, "freightWarehousing");

      expect(dataRow.assetsPaid).toBe(0);
      expect(dataRow.assetsToDate).toBe(0);
      expect(dataRow.depositsPaid).toBe(100);
      expect(dataRow.depositsToDate).toBe(100);
      expect(dataRow.rawNotProcessed).toBe(0);
      expect(dataRow.notProcessed).toBe(0);

      expect(dataRow.notProcessedAssetsPaid).toBe(175);
      expect(dataRow.notProcessedDepositsPaid).toBe(275);
    });
  });
});
