import React, { Component, Fragment, useContext } from "react";
import PropTypes from "prop-types";
import BWModels from "benjaminwest-models";
import memoizeOne from "memoize-one";
import _isEqual from "lodash/isEqual";

import { LoaderContext } from "../../ui/Loader";
import { Content } from "../../layout/Content";
import { Wrapper, MainSection } from "./components";
import { FormGrid, FormikForm } from "../../forms";
import createSpecDetailMainFields from "./createSpecDetailMainFields";
import Paper from "../../mui/core/Paper";
import TagText from "../../ui/Tags/TagText";
import { BWIntent } from "../../ui/BWIntent";
import SpecDetailDragAndDropList from "./SpecDetailDragAndDropList";
import AdditionalInformation from "./AdditionalInformation";
import RequirementsSection from "./RequirementsSection";
import TargetSectionForm from "./TargetSection/TargetSectionForm";
import DetailAndCycle from "./DetailAndCycle";

import propTypes from "../../../constants/propTypes";
import PricingSection from "./PricingSection";
import QuantityForm from "./QuantityForm/QuantityForm";
import { sortById } from "../../../utils/sortUtils";
import GLCodesSection from "./GLCodesSection";
import RevisedBanner from "./RevisedBanner";
import { withRevisionReference } from "../../../withPORevision";
import { buildOverridableAttrSelector } from "../../../utils/selectors";
import { overrideAttributes } from "./overridableAttrs";
import { isAnyShipmentApprovedToPay } from "../Specs/specContainerFunctions";
import { LabeledTextContext } from "../../../withPORevision";
import CurrencySection from "./CurrencySection/CurrencySection";
import { isPOChangeDisabled } from "../PurchaseOrderDetail/utils";
import { getQuantityErrorMessage } from "../Specs/SpecFormikForm";
import DirtyAutoSaveFormPrompt from "../../forms/DirtyAutoSaveFormPrompt";
import { dataComponentId } from "./selectors";
import { useIsProjectClosed } from "../../hooks/useIsProjectClosed";
import RelatedSpecs from "./RelatedSpecs";

const ANIMATION_DURATION_MS = 1000;
export class SpecDetailPage extends Component {
  get specSchema() {
    return BWModels.loadSchema("Spec");
  }

  componentDidMount() {
    setTimeout(
      () =>
        window.scrollTo({
          top: 0,
        }),
      ANIMATION_DURATION_MS
    );
  }

  renderStatus = ({ status }) => {
    switch (status) {
      case "Committed":
        return <TagText intent={BWIntent.PRIMARY}>{status}</TagText>;
      case "Based on committed price":
        return (
          <TagText
            isSimple
            intent={BWIntent.NONE}
            style={{ fontWeight: "normal" }}
          >
            {status}
          </TagText>
        );
    }
  };

  handleOnClickMenu = specDetail => {
    const { openEditSpecDetailModal } = this.props;

    openEditSpecDetailModal(specDetail);
  };

  get specDetails() {
    const { spec, loading } = this.props;
    let specDetails = [];
    if (!spec) return specDetails;

    specDetails = Object.values(spec.specDetails) || [];
    return loading && specDetails.length === 0
      ? Array(5).fill({})
      : sortById(specDetails);
  }

  isEditable = spec => {
    return !isAnyShipmentApprovedToPay(spec.shipments);
  };

  getInitialValues = memoizeOne(spec => {
    return {
      ...spec,
    };
  }, _isEqual);

  getOverridableAttrs = buildOverridableAttrSelector(overrideAttributes);

  render() {
    const {
      openAddDetailModal,
      clientId,
      onOpenNotesModal,
      onViewPO,
      nextSpecId,
      lastSpecId,
      onOpenDeleteSpec,
      onOpenDuplicateSpec,
      onEditDetailModal,
      role,
      backTo,
      updateLoading,
      spec,
      projectId,
    } = this.props;
    return (
      <Fragment>
        <RevisedBanner spec={spec} clientId={clientId} />
        <Content>
          <FormikForm
            initialValues={this.getInitialValues(spec)}
            valueOverrides={this.getOverridableAttrs(spec)}
            onSubmit={this.props.onUpdateSpec}
            validationSchema={this.specSchema}
            enableReinitialize
            autoSave
            ignoreCache
            noDelayFields={[
              "clientBudgetCents",
              "priceCents",
              /File$/,
              "requirements",
              "isTaxExempt",
              "isPlaceholder",
              "vendorId",
              "specRequirements",
              "specDetailIdSequence",
            ]}
            delayAutoSave={4000}
          >
            {({ handleSubmit, values, ...formikProps }) => (
              <Fragment>
                <DetailAndCycle
                  spec={spec}
                  clientId={clientId}
                  onOpenNotesModal={onOpenNotesModal}
                  onViewPO={onViewPO}
                  onOpenDeleteSpec={onOpenDeleteSpec}
                  onOpenDuplicateSpec={onOpenDuplicateSpec}
                  nextSpecId={nextSpecId}
                  lastSpecId={lastSpecId}
                  role={role}
                  backTo={backTo}
                />
                <Wrapper>
                  <MainInformation
                    handleOnClickMenu={this.handleOnClickMenu}
                    isEditable={this.isEditable(spec)}
                    projectId={projectId}
                    spec={spec}
                    formikProps={formikProps}
                    updateLoading={updateLoading}
                    openAddDetailModal={openAddDetailModal}
                    onEditDetailModal={onEditDetailModal}
                    clientId={clientId}
                    values={values}
                    role={role}
                  />
                  <AdditionalInformation
                    purchaseOrder={spec.purchaseOrder}
                    vendor={spec.vendor}
                    spec={spec}
                    vendorContacts={spec.vendorContacts}
                  />
                </Wrapper>
                <DirtyAutoSaveFormPrompt dataComponentId={dataComponentId} />
              </Fragment>
            )}
          </FormikForm>
        </Content>
      </Fragment>
    );
  }
}

export const MainInformation = ({
  spec,
  formikProps,
  updateLoading,
  openAddDetailModal,
  clientId,
  values,
  handleOnClickMenu,
  onEditDetailModal,
  projectId,
  role,
}) => {
  const isProjectClosed = useIsProjectClosed();
  const isLabeled =
    useContext(LabeledTextContext) ||
    isPOChangeDisabled(spec.purchaseOrder) ||
    isProjectClosed;
  return (
    <MainSection>
      <Paper>
        <FormGrid
          purchaseOrder={spec.purchaseOrder}
          {...formikProps}
          fields={createSpecDetailMainFields(
            projectId,
            isProjectClosed,
            role,
            spec
          )}
        />
      </Paper>
      <LabeledTextContext.Provider value={isLabeled}>
        <LoaderContext.Provider value={{ loading: updateLoading }}>
          <RequirementsSection
            values={values}
            loading={updateLoading}
            isLabeled={isLabeled}
            role={role}
            specId={spec.id}
          />
        </LoaderContext.Provider>
      </LabeledTextContext.Provider>
      <Paper>
        <QuantityForm
          spec={spec}
          quantityErrorMessage={getQuantityErrorMessage(spec, values)}
          {...formikProps}
        />
      </Paper>

      <Paper>
        <CurrencySection projectId={projectId} spec={spec} {...formikProps} />
      </Paper>

      <Paper>
        <PricingSection
          spec={spec}
          purchaseOrder={spec.purchaseOrder}
          isEditable={!(spec.totalQuantityPaid > 0)}
        />
      </Paper>
      <TargetSectionForm values={values} {...formikProps} />
      <SpecDetailDragAndDropList
        spec={values}
        purchaseOrder={spec.purchaseOrder}
        openAddDetailModal={openAddDetailModal}
        handleOnClickMenu={handleOnClickMenu}
        onEditDetailModal={onEditDetailModal}
        {...formikProps}
      />
      <RelatedSpecs spec={spec} />
      <GLCodesSection
        purchaseOrder={spec.purchaseOrder}
        clientId={clientId}
        {...formikProps}
      />
    </MainSection>
  );
};

export default withRevisionReference(SpecDetailPage);

SpecDetailPage.propTypes = {
  openAddDetailModal: PropTypes.func.isRequired,
  onEditDetailModal: PropTypes.func.isRequired,
  onOpenNotesModal: PropTypes.func.isRequired,
  onViewPO: PropTypes.func.isRequired,
  spec: propTypes.spec,
  openEditSpecDetailModal: PropTypes.func.isRequired,
  dataComponent: PropTypes.object,
  model: PropTypes.object,
  apiRoute: PropTypes.string,
  includes: PropTypes.array,
  onUpdateSpec: PropTypes.func,
  onOpenDeleteSpec: PropTypes.func,
  onOpenDuplicateSpec: PropTypes.func,
  clientId: PropTypes.string,
  projectId: PropTypes.string,
  revisionReference: PropTypes.string,
  nextSpecId: PropTypes.string,
  lastSpecId: PropTypes.string,
  role: propTypes.userRole,
  backTo: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  updateLoading: PropTypes.bool,
  loading: PropTypes.bool,
};
