import InvoiceData from "../InvoiceData";

const invoice = {
  merchandiseMaximum: 100,
  merchandiseDepositsPaid: 10,
  merchandiseOtherDepositsPaid: 20,

  overageDepositsPaid: 30,
  overageOtherDepositsPaid: 40,

  otherCostDepositsPaid: 50,
  otherCostOtherDepositsPaid: 60,

  freightWarehousingDepositsPaid: 70,
  freightWarehousingOtherDepositsPaid: 80,

  installDepositsPaid: 80,
  installOtherDepositsPaid: 90,

  taxDepositsPaid: 0,
  taxOtherDepositsPaid: 0,
};

describe("Validator", () => {
  describe("tax", () => {
    it("install and freight taxable: is valid when taxDepositsPaid is less than total taxable", () => {
      const invoiceData = new InvoiceData({
        ...invoice,
        purchaseOrder: {
          project: {
            isInstallTaxable: true,
            isFreightTaxable: true,
            salesTaxTolerancePercent: 0.1025,
            salesTaxPercent: 0.812,
          },
        },
      });

      //Total taxable is 530*0.1025 = 54.325
      const { valid, helperText } = invoiceData.validate(
        {
          taxDepositsPaid: 54.3,
        },
        {
          field: "tax",
        }
      );
      expect(valid).toBe(true);
      expect(helperText).toBe(" ");
    });

    it("install is taxable: is valid when taxDepositsPaid is less than total taxable", () => {
      const invoiceData = new InvoiceData({
        ...invoice,
        purchaseOrder: {
          project: {
            isInstallTaxable: true,
            isFreightTaxable: false,
            salesTaxTolerancePercent: 0.1025,
            salesTaxPercent: 0.812,
          },
        },
      });

      {
        //Total taxable is 380*0.1025 = 38.95
        const { valid, helperText } = invoiceData.validate(
          {
            taxDepositsPaid: 38.95,
          },
          {
            field: "tax",
          }
        );
        expect(valid).toBe(true);
        expect(helperText).toBe(" ");
      }

      {
        //Total taxable is 380*0.1025 = 38.95
        const { valid } = invoiceData.validate(
          {
            taxDepositsPaid: 39,
          },
          {
            field: "tax",
          }
        );
        expect(valid).toBe(false);
      }
    });
  });

  describe("merchandise", () => {
    it("positive notProcessed: merchandiseDepositsPaid needs to be between merchandiseOtherDepositsPaid and merchandiseMaximum", () => {
      const tests = [
        {
          value: -21,
          expectValid: false,
        },
        {
          value: 0,
          expectValid: true,
        },
        {
          value: -20,
          expectValid: true,
        },
        {
          value: 80,
          expectValid: true,
        },
        {
          value: 81,
          expectValid: false,
        },
      ];
      const invoiceData = new InvoiceData(invoice);

      tests.forEach(({ value, expectValid }) => {
        const { valid } = invoiceData.validate(
          {
            merchandiseDepositsPaid: value,
          },
          {
            field: "merchandise",
          }
        );
        expect(valid).toBe(expectValid);
      });
    });

    it("positive notProcessed: minimum is the negative max between merchandiseOtherDepositsPaid and merchandiseAssetsPaid", () => {
      const invoiceData = new InvoiceData({
        ...invoice,
        merchandiseAssetsPaid: 30,
      });
      const { valid } = invoiceData.validate(
        {
          merchandiseDepositsPaid: -30,
        },
        {
          field: "merchandise",
        }
      );
      expect(valid).toBe(true);
    });
  });
});
