import React from "react";
import BWModels from "benjaminwest-models";
import PropTypes from "prop-types";
import AddCircle from "@material-ui/icons/AddCircle";

import { Content } from "../../layout/Content";
import Paper from "../../mui/core/Paper";
import ConsultingCompany from "../../../models/ConsultingCompany";
import { BWGridAPI, GridHeader, GridTable } from "../../ui/BWGrid";
import LocationCombined from "../../ui/LocationCombined";
import FavoriteControl from "./FavoriteControl";

const columnOptions = () => {
  const consultingSchema = BWModels.loadSchema("ConsultingCompany");
  const types = consultingSchema.__utils.types;
  return {
    isFavorite: {
      title: " ",
      render: function renderFavorite(props) {
        return <FavoriteControl {...props} />;
      },
    },
    type: {
      width: "200px",
      filter: "select",
      filterOptions: {
        options: types,
      },
    },
    name: {
      filter: true,
    },
    "location.address": {
      title: "Address",
      render: LocationCombined,
      filter: true,
    },
  };
};

const getRowMenu = ({
  handleEditConsultingCompany,
  handleDeleteConsultingCompany,
}) => () => [
  { text: "Edit", onClick: handleEditConsultingCompany },
  { separator: true },
  { text: "Delete", onClick: handleDeleteConsultingCompany },
];

const ConsultingCompaniesPage = ({
  dataComponent,
  handleRowClick,
  handleAddConsultingCompany,
  handleEditConsultingCompany,
  handleDeleteConsultingCompany,
}) => (
  <Content>
    <Paper>
      <BWGridAPI
        apiRoute="consulting-companies"
        model={ConsultingCompany}
        dataComponent={dataComponent}
        includes={["location"]}
        apiFilters={{
          sort: [{ columnName: "name" }],
          params: { omitScopeFilter: true, sortByFavorite: true },
        }}
        defaultSorting={[
          {
            columnName: "name",
            direction: "asc",
          },
        ]}
      >
        <GridHeader
          headerText={`${dataComponent.totalRows} Companies`}
          actions={[
            {
              text: "ADD COMPANY",
              icon: <AddCircle />,
              handler: handleAddConsultingCompany,
            },
          ]}
        />
        <GridTable
          columns={["isFavorite", "name", "type", "location.address"]}
          onClick={handleRowClick}
          columnOptions={columnOptions()}
          rowMenu={getRowMenu({
            handleEditConsultingCompany,
            handleDeleteConsultingCompany,
          })}
        />
      </BWGridAPI>
    </Paper>
  </Content>
);

ConsultingCompaniesPage.propTypes = {
  dataComponent: PropTypes.object.isRequired,
  handleRowClick: PropTypes.func.isRequired,
  handleAddConsultingCompany: PropTypes.func.isRequired,
  handleEditConsultingCompany: PropTypes.func.isRequired,
  handleDeleteConsultingCompany: PropTypes.func.isRequired,
};

export default ConsultingCompaniesPage;
