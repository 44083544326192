import React, { Fragment } from "react";

import useUserRole from "../../../hooks/useUserRole";
import { roleCan } from "../../../../utils/roleUtils";

import GlobalTerms from "./GlobalTerms";
import PaymentTerms from "../PaymentTerms";

const Terms = () => {
  const role = useUserRole();
  return (
    <Fragment>
      <GlobalTerms />
      {roleCan(role, "manage-payment-terms") ? <PaymentTerms /> : null}
    </Fragment>
  );
};

export default Terms;
