import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { BaseModel } from "@blue-chip/core";
import styled from "styled-components";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Search from "@material-ui/icons/Search";
import {
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell as MuiTableCell,
  TableBody,
} from "@material-ui/core";

import { Title } from "../../../../ui/Typography/SectionTitle";
import BoldText from "../../../../ui/Typography/BoldText";
import RegularTextSmall from "../../../../ui/Typography/RegularTextSmall";
import { ReportLink } from "../../../Reports/ReportList";
import { usePerformRetrieveList } from "../../../../hooks/usePerformRetrieveList";
import Loader from "../../../../ui/Loader";
import propTypes from "../../../../../constants/propTypes";

const ContentWrapper = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  @media (max-width: 600px) {
    padding: 8px;
  }
`;
const ItemPaperWrapper = styled(Paper)`
  overflow-x: auto;
  @media (max-width: 600px) {
    margin-top: 0px;
  }
`;
const ReportLinkWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const StyledTitle = styled(Title)`
  padding: 24px 24px 0px 24px;
`;
const TableCell = styled(MuiTableCell)`
  padding-left: 24px;
`;

const ReportsContainer = props => {
  const {
    projectId,
    generateAndDownloadReport,
    reportsLoader,
    project: {
      property: { entity: { clientId } } = { entity: { clientId: "-1" } },
    },
  } = props;
  const handleDownloadReport = (
    { id, templateName },
    format,
    showPreview = false
  ) => {
    generateAndDownloadReport({
      id,
      format,
      projectId,
      showPreview,
      templateName,
    });
  };

  const filters = useMemo(
    () => ({
      pageSize: -1,
      sort: [
        {
          columnName: "projectReport.projectReportCategoryId",
          direction: "asc",
        },
        { columnName: "projectReport.sequenceIndex", direction: "asc" },
      ],
      rootFilters: {
        $where: {
          clientId,
          isActive: true,
        },
      },
    }),
    [clientId]
  );
  const dataComponent = useMemo(
    () => ({
      dataComponentId: "clientProjectReportsDataComponentId",
      model: BaseModel,
      includes: ["projectReport.projectReportCategory"],
      apiRoute: "client-project-reports",
    }),
    []
  );
  const { isLoading, data: clientProjectReports = [] } = usePerformRetrieveList(
    dataComponent,
    filters
  );

  const projectReports = clientProjectReports.map(
    ({ projectReport }) => projectReport
  );

  return (
    <ContentWrapper>
      <ItemPaperWrapper>
        <StyledTitle>
          <Loader loading={isLoading} height="65px">
            {projectReports.length} Reports
          </Loader>
        </StyledTitle>
        <Loader loading={isLoading} height="65px" count={10}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ width: "400px" }}>
                  <RegularTextSmall>Name</RegularTextSmall>
                </TableCell>
                <TableCell>
                  <RegularTextSmall>Download</RegularTextSmall>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {projectReports.map((projectReport, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <BoldText style={{ whiteSpace: "nowrap" }}>
                      {projectReport.name}
                      {projectReport.isSingle
                        ? ""
                        : ` - ${projectReport.projectReportCategory.name}`}
                    </BoldText>
                  </TableCell>
                  <TableCell>
                    <ReportLinkWrapper>
                      {projectReport.hasPdf && (
                        <ReportLink
                          {...projectReport}
                          label="As .PDF"
                          Icon={ArrowDownward}
                          loading={
                            reportsLoader[
                              `${projectReport.templateName}-pdf-false`
                            ]
                          }
                          onClick={() =>
                            handleDownloadReport(projectReport, "pdf")
                          }
                        />
                      )}
                      {projectReport.hasXls && (
                        <ReportLink
                          {...projectReport}
                          label="As .XLS"
                          Icon={ArrowDownward}
                          loading={
                            reportsLoader[
                              `${projectReport.templateName}-xls-false`
                            ]
                          }
                          onClick={() =>
                            handleDownloadReport(projectReport, "xls")
                          }
                        />
                      )}
                      {projectReport.hasPdf && (
                        <ReportLink
                          {...projectReport}
                          label="Preview"
                          Icon={Search}
                          loading={
                            reportsLoader[
                              `${projectReport.templateName}-pdf-true`
                            ]
                          }
                          onClick={() =>
                            handleDownloadReport(projectReport, "pdf", true)
                          }
                        />
                      )}
                    </ReportLinkWrapper>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Loader>
      </ItemPaperWrapper>
    </ContentWrapper>
  );
};
ReportsContainer.propTypes = {
  project: propTypes.project,
  projectId: PropTypes.string,
  generateAndDownloadReport: PropTypes.func,
  reportsLoader: propTypes.reportsLoader,
};

export default ReportsContainer;
