// eslint-disable-next-line no-unused-vars
import InvoiceData from "../../../../../models/Invoice/InvoiceData";

const hidableNotProcessedFields = ["tax", "accruedUseTax", "discount"];

/**
 * Generates a row object for the invoice data grid.
 *
 * @param {string} id - The unique identifier for the row.
 * @param {string} title - The title or label for the row.
 * @param {InvoiceData} invoiceData - The invoice data object containing financial details.
 * @param {"merchandise" | "overage" | "tax" | "freightWarehousing" | "install" | "otherCost"} field - The key representing a specific category of the invoice to be processed.
 * @returns {Object} A row object containing financial data fields such as assetsPaid, assetsToDate, depositsPaid, depositsToDate, net, and notProcessed.
 */
const generateRow = (id, title, invoiceData, field) => {
  return {
    id,
    title,
    field,
    assetsPaid: invoiceData[field].assetsPaid,
    assetsToDate: invoiceData[field].assetsToDate,
    depositsPaid: invoiceData[field].depositsPaid,
    depositsToDate: invoiceData[field].depositsToDate,
    net: invoiceData[field].net,
    notProcessed: hidableNotProcessedFields.includes(field)
      ? ""
      : invoiceData[field].notProcessed,
  };
};

export default invoiceData => {
  return [
    {
      id: "0",
      title: " ",
      assetsPaid: "Paid",
      assetsToDate: "To Date",
      depositsPaid: "Paid",
      depositsToDate: "To Date",
      net: "",
      notProcessed: "",
    },
    generateRow("1", "Merchandise", invoiceData, "merchandise"),
    generateRow("2", "Overage", invoiceData, "overage"),
    generateRow(
      "3",
      `Tax (${invoiceData.invoice.taxPercent}%)`,
      invoiceData,
      "tax"
    ),
    generateRow(
      "4",
      `Accrued Use Tax (${invoiceData.invoice.accruedUseTaxPercent}%)`,
      invoiceData,
      "accruedUseTax"
    ),
    generateRow("5", "Freight/Warehousing", invoiceData, "freightWarehousing"),
    generateRow("6", "Install", invoiceData, "install"),
    generateRow("7", "Other costs", invoiceData, "otherCost"),
    generateRow("8", "Discount", invoiceData, "discount"),
    {
      id: "10",
      title: "Check Request Total",
      assetsPaid: invoiceData.totalAssetsPaid,
      assetsToDate: invoiceData.totalAssetsToDate,
      depositsPaid: invoiceData.totalDepositsPaid,
      depositsToDate: invoiceData.totalDepositsToDate,
      net: invoiceData.total,
      notProcessed: "",
    },
  ];
};
