import React, { Fragment, useMemo } from "react";
import { InputLabel } from "@material-ui/core";
import { BaseModel } from "@blue-chip/core";
import _groupBy from "lodash/groupBy";

import { FormGrid, FormikForm } from "../../../forms";
import { usePerformRetrieveList } from "../../../hooks/usePerformRetrieveList";
import CheckboxWithError from "../../../inputs/CheckboxWithError";
import { Paper } from "../../../mui/core";
import Loader from "../../../ui/Loader";
import { performUpdateRequest } from "../../../../actions/dataComponentActions";
import useActions from "../../../hooks/useActions";
import { setAutoSaveComponentId } from "../../../../actions/layoutActions";

const reportFields = reportsByCategory => {
  return {
    groups: Object.keys(reportsByCategory).map(label => {
      const clientProjectReports = reportsByCategory[label];
      return {
        items: [
          {
            element: <InputLabel>{label}</InputLabel>,
            grid: { xs: 12 },
          },
          ...clientProjectReports.map(({ i, projectReport: { name } }) => ({
            input: {
              InputComponent: CheckboxWithError,
              ignoreCaption: true,
              label: name,
              name: `clientProjectReports[${i}].isActive`,
            },
            grid: { xs: 6 },
          })),
        ],
      };
    }),
  };
};

const ReportsContainer = ({ client: { id: clientId } }) => {
  const filters = useMemo(
    () => ({
      pageSize: -1,
      sort: [{ columnName: "projectReport.sequenceIndex", direction: "asc" }],
      rootFilters: {
        $where: {
          clientId,
        },
      },
    }),
    [clientId]
  );
  const dataComponentDef = useMemo(
    () => ({
      dataComponentId: "clientDetailsProjectReportsDataComponentId",
      model: BaseModel,
      includes: ["projectReport.projectReportCategory"],
      apiRoute: "client-project-reports",
    }),
    []
  );
  const { isLoading, data } = usePerformRetrieveList(
    dataComponentDef,
    filters,
    []
  );

  const clientProjectReports = data.sort(
    (a, b) => a.projectReport.sequenceIndex - b.projectReport.sequenceIndex
  );

  const fields = useMemo(
    () =>
      reportFields(
        _groupBy(
          clientProjectReports.map((clientProjectReport, i) => ({
            i,
            ...clientProjectReport,
          })),
          "projectReport.projectReportCategory.name"
        )
      ),
    [clientProjectReports]
  );

  const actions = useActions({ performUpdateRequest, setAutoSaveComponentId });
  const handleSubmit = ({ clientProjectReports }) => {
    actions.setAutoSaveComponentId(dataComponentDef.dataComponentId);
    actions.performUpdateRequest(
      dataComponentDef.dataComponentId,
      clientProjectReports.map(
        ({ projectReport, ...clientProjectReport }) => clientProjectReport
      )
    );
  };

  return (
    <Paper>
      <Loader loading={isLoading} height="65px" count={10}>
        <FormikForm
          onSubmit={handleSubmit}
          initialValues={{ clientProjectReports }}
          autoSave
          enableReinitialize={isLoading}
          ignoreCache
        >
          {({ handleSubmit, values, errors, ...formikProps }) => (
            <Fragment>
              <FormGrid
                fields={fields}
                values={values}
                errors={errors}
                {...formikProps}
              />
            </Fragment>
          )}
        </FormikForm>
      </Loader>
    </Paper>
  );
};

export default ReportsContainer;
