import { push } from "connected-react-router";
import _get from "lodash/get";

import {
  getDataComponent,
  getDataComponentFlattenedRequestState,
} from "../../../reducers/dataComponentReducer";
import * as REQUEST_TYPES from "../../../constants/RequestTypes";
import {
  getBluechipResourceById,
  getBluechipResourcesByType,
} from "../../../utils/bluechipUtils";
import {
  clearResources,
  initDataComponent,
  performRetrieveListRequest,
  performUpdateRequest,
  setReload,
} from "../../../actions/dataComponentActions";
import { populateCountryResources } from "../../../actions/countryStateActions";
import {
  openModalDialog,
  closeModalDialog,
  setAutoSaveComponentId,
  setHeaderTitle,
  showSnackNotificationAction,
} from "../../../actions/layoutActions";
import { openNotesModal } from "../../../actions/notesActions";
import { fetchPurchaseOrderContacts } from "../../../actions/purchaseOrdersContactActions";
import {
  revertFromRevision,
  fetchPurchaseOrder,
} from "../../../actions/purchaseOrdersActions";
import { poContactdataComponentId } from "../../../actions/purchaseOrdersContactActions";

export const dataComponentId = "PODetail";

export const mapStateToProps = (state, ownProps) => {
  const { poId: purchaseOrderId, clientId, projectId } = ownProps.match.params;

  const dataComponent = getDataComponentFlattenedRequestState(
    dataComponentId,
    state,
    REQUEST_TYPES.FIND
  );

  const purchaseOrderInstance = getBluechipResourceById(
    dataComponent,
    state,
    purchaseOrderId
  );

  return {
    dataComponent: getDataComponent(dataComponentId, state),
    purchaseOrderId,
    clientId,
    loading: [dataComponent.loading].some(loading => loading),
    projectId,
    nextPOId: _get(dataComponent, "links.next.meta.id"),
    lastPOId: _get(dataComponent, "links.last.meta.id"),
    purchaseOrderInstance,
    purchaseOrderContacts: getBluechipResourcesByType(
      poContactdataComponentId,
      state
    ),
  };
};

export const mapDispatchToProps = {
  populateCountryResources,
  initDataComponent,
  performRetrieveListRequest,
  openModalDialog,
  closeModalDialog,
  setHeaderTitle,
  fetchPurchaseOrderContacts,
  openNotesModal,
  setAutoSaveComponentId,
  performUpdateRequest,
  fetchPurchaseOrder,
  revertFromRevision,
  push,
  setReload,
  showSnackNotificationAction,
  clearResources,
};
