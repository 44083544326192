import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import {
  closeModalDialog,
  showSnackNotificationAction,
} from "../../../../../../actions/layoutActions";
import { EditScope } from "./EditScope";
import {
  performUpdateRequest,
  setReload,
} from "../../../../../../actions/dataComponentActions";
import { useUpdateProcessRequests } from "../../../../../hooks/useProcessRequest";
import { getDataComponent } from "../../../../../../reducers/dataComponentReducer";
import { convertServerErrorToFormikErrors } from "../../../../../../utils/formValidationUtils";
import propTypes from "../../../../../../constants/propTypes";
import { scopeDataComponentId } from "../Scopes";

const EditScopeContainer = ({
  scope,
  dataComponent,
  performUpdateRequest,
  showSnackNotificationAction,
  setReload,
  closeModalDialog,
}) => {
  useUpdateProcessRequests(dataComponent, {
    onSuccess: () => {
      showSnackNotificationAction("Scope saved successfully.");
      setReload(scopeDataComponentId, true);
      closeModalDialog();
    },
    onError: error => {
      const convertedError = convertServerErrorToFormikErrors(error);
      showSnackNotificationAction(convertedError.name);
    },
  });

  const handleEditScope = values => {
    performUpdateRequest(scopeDataComponentId, scope.id, values);
  };

  return (
    <EditScope
      scope={scope}
      handleEditScope={handleEditScope}
      closeModalDialog={closeModalDialog}
    />
  );
};

EditScopeContainer.propTypes = {
  scope: propTypes.scope.isRequired,
  dataComponent: propTypes.dataComponent,
  performUpdateRequest: PropTypes.func.isRequired,
  showSnackNotificationAction: PropTypes.func.isRequired,
  setReload: PropTypes.func.isRequired,
  closeModalDialog: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    dataComponent: getDataComponent(scopeDataComponentId, state),
  };
};

const mapDispatchToProps = {
  performUpdateRequest,
  showSnackNotificationAction,
  setReload,
  closeModalDialog,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditScopeContainer);
