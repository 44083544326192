import get from "lodash.get";

import Data from "./Data";
import Validator from "./Validator";

export default class InvoiceData extends Data {
  constructor(invoice) {
    super(invoice);

    this.invoice = invoice;

    this.validator = new Validator(this);
  }

  validate(...args) {
    return this.validator.validate(...args);
  }

  get totalAssetsPaid() {
    return (
      this.merchandise.assetsPaid +
      this.overage.assetsPaid +
      this.tax.assetsPaid +
      this.accruedUseTax.assetsPaid +
      this.freightWarehousing.assetsPaid +
      this.install.assetsPaid +
      this.otherCost.assetsPaid +
      this.discount.assetsPaid
    );
  }

  get totalAssetsToDate() {
    return (
      this.merchandise.assetsToDate +
      this.overage.assetsToDate +
      this.tax.assetsToDate +
      this.accruedUseTax.assetsToDate +
      this.freightWarehousing.assetsToDate +
      this.install.assetsToDate +
      this.otherCost.assetsToDate +
      this.discount.assetsToDate
    );
  }

  get totalDepositsPaid() {
    return (
      this.merchandise.depositsPaid +
      this.overage.depositsPaid +
      this.tax.depositsPaid +
      this.accruedUseTax.depositsPaid +
      this.freightWarehousing.depositsPaid +
      this.install.depositsPaid +
      this.otherCost.depositsPaid +
      this.discount.depositsPaid
    );
  }

  get totalDepositsToDate() {
    return (
      this.merchandise.depositsToDate +
      this.overage.depositsToDate +
      this.tax.depositsToDate +
      this.accruedUseTax.depositsToDate +
      this.freightWarehousing.depositsToDate +
      this.install.depositsToDate +
      this.otherCost.depositsToDate +
      this.discount.depositsToDate
    );
  }

  get total() {
    return (
      this.merchandise.net +
      this.overage.net +
      this.tax.net +
      this.accruedUseTax.net +
      this.freightWarehousing.net +
      this.install.net +
      this.otherCost.net +
      this.discount.net
    );
  }

  get totalDepositsTaxable() {
    return this.taxableRows.reduce(
      (sum, row) => sum + this[row].depositsToDate,
      0
    );
  }

  get totalAssetsTaxable() {
    return this.taxableRows.reduce(
      (sum, row) => sum + this[row].assetsToDate,
      0
    );
  }

  get taxableRows() {
    const columns = ["merchandise", "overage", "otherCost"];
    const isFreightTaxable = get(
      this,
      "invoice.purchaseOrder.project.isFreightTaxable"
    );
    const isInstallTaxable = get(
      this,
      "invoice.purchaseOrder.project.isInstallTaxable"
    );
    if (isFreightTaxable) {
      columns.push("freightWarehousing");
    }
    if (isInstallTaxable) {
      columns.push("install");
    }
    return columns;
  }
}
