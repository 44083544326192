import React from "react";
import styled from "styled-components";
import BWModels from "benjaminwest-models";

import { FormGrid, FormikForm } from "../../../../forms";
import { ActionButtons } from "../../../../forms/ActionButtons";
import { inputs } from "../../../../inputs/inputConfigs";
import { vendorCertificationDataComponentId } from "../VendorCertificationsContainer";

const Wrapper = styled.div``;

const fields = {
  groups: [
    {
      items: [
        {
          input: inputs.name,
          grid: { xs: 12 },
        },
        {
          input: { ...inputs.description, required: true },
          grid: { xs: 12 },
        },
      ],
    },
  ],
};

export const EditVendorCertification = ({
  vendorCertification,
  handleEditVendorCertification,
  closeModalDialog,
}) => {
  return (
    <FormikForm
      initialValues={{
        name: vendorCertification.name,
        description: vendorCertification.description,
      }}
      onSubmit={handleEditVendorCertification}
      validationSchema={BWModels.loadSchema("Certification")}
    >
      {({ handleSubmit, ...formikProps }) => (
        <Wrapper>
          <FormGrid fields={fields} {...formikProps} />
          <ActionButtons
            onSend={handleSubmit}
            onCancel={closeModalDialog}
            sendButtonText="Save"
            listeners={[vendorCertificationDataComponentId]}
            isModal
          />
        </Wrapper>
      )}
    </FormikForm>
  );
};
