import InvoiceData from "../InvoiceData";

const invoice = {
  merchandiseDepositsPaid: 10,
  merchandiseOtherDepositsPaid: 20,
  overageDepositsPaid: 30,
  overageOtherDepositsPaid: 40,
  otherCostDepositsPaid: 50,
  otherCostOtherDepositsPaid: 60,
  freightWarehousingDepositsPaid: 70,
  freightWarehousingOtherDepositsPaid: 80,
  installDepositsPaid: 80,
  installOtherDepositsPaid: 90,
};

describe("InvoiceData", () => {
  it("isInstallTaxable and isFreightTaxable is true and totalDepositsTaxable returns the sum of taxable rows", () => {
    const invoiceData = new InvoiceData({
      ...invoice,
      purchaseOrder: {
        project: {
          isInstallTaxable: true,
          isFreightTaxable: true,
        },
      },
    });
    expect(invoiceData.totalDepositsTaxable).toEqual(530);
  });

  it("isFreightTaxable is false and totalDepositsTaxable returns the sum of taxable rows", () => {
    const invoiceData = new InvoiceData({
      ...invoice,
      purchaseOrder: {
        project: {
          isInstallTaxable: true,
          isFreightTaxable: false,
        },
      },
    });
    expect(invoiceData.totalDepositsTaxable).toEqual(380);
  });

  it("isInstallTaxable is false and totalDepositsTaxable returns the sum of taxable rows", () => {
    const invoiceData = new InvoiceData({
      ...invoice,
      purchaseOrder: {
        project: {
          isInstallTaxable: false,
          isFreightTaxable: true,
        },
      },
    });
    expect(invoiceData.totalDepositsTaxable).toEqual(360);
  });

  it("taxable rows", () => {
    {
      const invoice = {
        purchaseOrder: {
          project: {
            isInstallTaxable: true,
            isFreightTaxable: true,
          },
        },
      };
      const invoiceData = new InvoiceData(invoice);
      expect(invoiceData.taxableRows).toEqual([
        "merchandise",
        "overage",
        "otherCost",
        "freightWarehousing",
        "install",
      ]);
    }

    {
      const invoice = {
        purchaseOrder: {
          project: {
            isInstallTaxable: true,
            isFreightTaxable: false,
          },
        },
      };
      const invoiceData = new InvoiceData(invoice);
      expect(invoiceData.taxableRows).toEqual([
        "merchandise",
        "overage",
        "otherCost",
        "install",
      ]);
    }

    {
      const invoice = {
        purchaseOrder: {
          project: {
            isInstallTaxable: false,
            isFreightTaxable: false,
          },
        },
      };
      const invoiceData = new InvoiceData(invoice);
      expect(invoiceData.taxableRows).toEqual([
        "merchandise",
        "overage",
        "otherCost",
      ]);
    }
  });
});
