import { isSuperAdmin } from "../../../../utils/roleUtils";
import { inputs } from "../../../inputs/inputConfigs";

export default (userRole, isUpdate) => {
  const groups = [
    {
      items: [
        {
          input: {
            ...inputs.vendorName,
            required: true,
          },
          grid: { xs: 12, sm: 12, md: 4 },
        },
        {
          input: inputs.poName,
          grid: { xs: 12, sm: 12, md: 4 },
        },
        {
          input: inputs.w9Name,
          grid: { xs: 12, sm: 12, md: 4 },
        },
        { input: inputs.website, grid: { xs: 12, sm: 12, md: 4 } },
        {
          input: {
            ...inputs.vendorCategoryId,
            required: true,
          },
          grid: { xs: 12, sm: 12, md: 4 },
        },
      ],
    },
    {
      items: [
        {
          input: {
            ...inputs.location,
            required: true,
          },
          grid: { xs: 12, sm: 12, md: 4 },
        },
        { input: inputs.locationAddress2, grid: { xs: 12, sm: 12, md: 4 } },
        {
          input: {
            ...inputs.locationCity,
            required: true,
          },
          grid: { xs: 12, sm: 12, md: 4 },
        },
        {
          input: {
            ...inputs.locationState,
            required: true,
          },
          grid: { xs: 12, sm: 12, md: 4 },
        },
        {
          input: {
            ...inputs.locationCountry,
            required: true,
          },
          grid: { xs: 12, sm: 12, md: 4 },
        },
        {
          input: {
            ...inputs.locationPostalCode,
            required: true,
          },
          grid: { xs: 12, sm: 12, md: 4 },
        },
      ],
    },
    {
      items: [
        { input: inputs.vendorStatus, grid: { xs: 12, sm: 12, md: 4 } },
        { input: inputs.products, grid: { xs: 12, sm: 12, md: 4 } },
        { input: inputs.certifications, grid: { xs: 12, sm: 12, md: 4 } },
        { input: inputs.vendorDiscount, grid: { xs: 12, sm: 12, md: 4 } },
      ],
    },
  ];

  const items = [
    {
      input: {
        ...inputs.comments,
        label: "Vendor Comments",
      },
      grid: { xs: 12, sm: 12 },
    },
  ];

  if (isSuperAdmin(userRole)) {
    items.push({
      input: {
        ...inputs.scopeId,
        label: "Scope",
        name: "scopeId",
        isDisabled: isUpdate,
      },
      grid: { xs: 4 },
    });
  }

  groups.push({
    items,
  });

  return {
    groups,
  };
};
