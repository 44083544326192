import React, { Fragment } from "react";
import PropTypes from "prop-types";
import noop from "lodash/noop";

import DeleteConfirmation from "../../../ui/DeleteConfirmation";
import useListSpecDetailComs from "../../Specs/BulkDeleteSpecs/useListSpecDetailComs";
import { Message } from "../../../ui/DangerConfirmation";
import { ActionButtons } from "../../../forms";

const DeleteSpec = ({ specId, customNumber, onDelete }) => {
  const { comSpecDetailIdSet } = useListSpecDetailComs(specId);

  if (comSpecDetailIdSet.size) {
    return (
      <Fragment>
        <Message>
          {customNumber} COM Spec can not be deleted as it is linked to other
          specs.
        </Message>
        <ActionButtons
          onDanger={noop}
          dangerButtonText={"Delete"}
          isModal
          emphasizeCancel
          additionalProps={{
            danger: {
              disabled: true,
            },
          }}
        />
      </Fragment>
    );
  }

  return <DeleteConfirmation title={customNumber} onDelete={onDelete} />;
};
DeleteSpec.propTypes = {
  specId: PropTypes.string.isRequired,
  customNumber: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
};
export default DeleteSpec;
