import React, { useMemo } from "react";
import PropTypes from "prop-types";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import startCase from "lodash/startCase";

import { FormPageSection } from "../../../layout/FormPageSection";
import {
  createCRSection,
  createCRDetailSection,
} from "./createCRSectionFields";
import { addDirtyInputMark } from "../withMarkDirtyInput";
import { ActionButtons, FormGrid } from "../../../forms";
import { dataComponentId } from "../InvoiceDetailContainer";

const isRequiredFieldEmpty = invoice => {
  return (
    !_get(invoice, "remitAddressId") ||
    !_get(invoice, "number") ||
    !_get(invoice, "invoicedDate") ||
    !_get(invoice, "dueDate")
  );
};

// eslint-disable-next-line complexity
export const validateOkToIssue = ({
  dirty,
  values: invoice,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  if (dirty) {
    return "Update required as changes detected on Invoice.";
  }

  if (isRequiredFieldEmpty(invoice)) {
    return "Invoice cannot be issue due to required fields missing.";
  }

  if (_get(invoice, "number") == "TBD") {
    return "Invalid invoice number.";
  }

  const negativeNotProcessedFields = Object.keys(invoice).filter(key => {
    if (!key.endsWith("NotProcessed")) {
      return false;
    }

    const row = key.replace("NotProcessed", "");
    const rowAssetsToDate = invoice[`${row}AssetsToDate`];
    const rowDepositsToDate = invoice[`${row}DepositsToDate`];
    const rowMaximum = invoice[`${row}Maximum`];

    const rowNotProcessed =
      Math.round((rowMaximum - (rowAssetsToDate + rowDepositsToDate)) * 1000) /
      1000;

    return rowNotProcessed < 0;
  });

  const notProcessedErrors = negativeNotProcessedFields.map(field =>
    startCase(field)
  );

  return _isEmpty(notProcessedErrors) || invoice.isRefundInvoice
    ? null
    : `Invoice cannot be marked as Ok to Issue. ${notProcessedErrors.join(
        notProcessedErrors.length === 2 ? " and " : ", "
      )}
        cannot be negative.`;
};

const Section = ({
  okToIssue,
  updateInvoice,
  allChangesLocked,
  isProjectClosed,
  isLoading,
  updateInvoiceIgnoreParameters,
  ...formikProps
}) => {
  const okToIssueError = useMemo(() => validateOkToIssue(formikProps), [
    formikProps,
  ]);

  const isOkToIssueDisabled = okToIssue || isProjectClosed || okToIssueError;
  const checkRequest = _get(formikProps.values, "checkRequest");
  const fields = useMemo(
    () =>
      addDirtyInputMark(
        createCRSection(
          checkRequest,
          !isOkToIssueDisabled,
          okToIssue,
          updateInvoice,
          okToIssueError
        )
      ),
    [
      checkRequest,
      isOkToIssueDisabled,
      okToIssue,
      okToIssueError,
      updateInvoice,
    ]
  );

  const detailFields = useMemo(
    () => addDirtyInputMark(createCRDetailSection(okToIssue, allChangesLocked)),
    [allChangesLocked, okToIssue]
  );

  return (
    <FormPageSection
      headerText="Check Request Details"
      fields={fields}
      {...formikProps}
    >
      <FormGrid fields={detailFields} />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          padding: "0 14px 14px",
        }}
      >
        <ActionButtons
          onSend={updateInvoiceIgnoreParameters}
          sendButtonText="UPDATE"
          disabled={allChangesLocked}
          hideCancelButton={true}
          listeners={[dataComponentId]}
          additionalProps={{
            send: { disableIfProjectClosed: true, isLoading },
          }}
        />
      </div>
    </FormPageSection>
  );
};

Section.propTypes = {
  canIssue: PropTypes.bool,
  okToIssue: PropTypes.bool,
  isLoading: PropTypes.bool,
  updateInvoiceIgnoreParameters: PropTypes.func.isRequired,
  updateInvoice: PropTypes.func.isRequired,
};

export default Section;
