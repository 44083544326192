import React from "react";

import Column from "./Column";

const afterSave = (value, { field, initialValues, setFieldTouched }) => {
  if (value == initialValues[field.name]) {
    return;
  }
  const touched = value != initialValues[field.name];

  setFieldTouched(field.name, touched);

  [
    { prefix: field.name, replace: "totalNet" },
    { prefix: "Paid", replace: "ToDate" },
    { prefix: "DepositsPaid", replace: "Net" },
    { prefix: "DepositsPaid", replace: "NotProcessed" },
    { prefix: "AssetsPaid", replace: "Net" },
    { prefix: "AssetsPaid", replace: "NotProcessed" },
  ].forEach(({ prefix, replace }) => {
    const cellName = `${field.name.replace(prefix, replace)}`;
    const isTouchable = ![
      "accruedUseTaxNotProcessed",
      "taxNotProcessed",
      "discountNotProcessed",
    ].includes(cellName);
    setFieldTouched(cellName, isTouchable);
  });

  ["Deposits", "Assets"].forEach(type => {
    if (field.name.includes(type)) {
      setFieldTouched(`total${type}Paid`, true);
      setFieldTouched(`total${type}ToDate`, true);
    }
  });
};

const buildColumn = (isEditable, projectCurrency) => (row, path, rowData) => {
  return (
    row && (
      <Column
        row={row}
        path={path}
        rowData={rowData}
        isEditable={isEditable}
        projectCurrency={projectCurrency}
      />
    )
  );
};

export const getColumnOptions = (isEditable = true, invoice) => {
  const projectCurrency = invoice.purchaseOrder?.projectCurrency;
  return {
    title: {
      title: " ",
      render: buildColumn(false),
    },
    assetsPaid: {
      title: "Assets",
      render: buildColumn(isEditable, projectCurrency),
      editable: isEditable,
      editableOptions: {
        afterSave,
      },
    },
    assetsToDate: {
      title: " ",
      render: buildColumn(isEditable, projectCurrency),
      editable: isEditable,
    },
    depositsPaid: {
      title: "Deposits",
      render: buildColumn(isEditable, projectCurrency),
      editableOptions: {
        afterSave,
      },
    },
    depositsToDate: {
      title: " ",
      render: buildColumn(isEditable, projectCurrency),
    },
    net: {
      title: "Net",
      render: buildColumn(isEditable, projectCurrency),
    },
    notProcessed: {
      render: buildColumn(isEditable, projectCurrency),
    },
  };
};
