import React from "react";
import styled from "styled-components";
import BWModels from "benjaminwest-models";

import { FormGrid, FormikForm } from "../../../../../forms";
import { ActionButtons } from "../../../../../forms/ActionButtons";
import { inputs } from "../../../../../inputs/inputConfigs";
import { scopeDataComponentId } from "../Scopes";
import TextInputWithError from "../../../../../inputs/TextInputWithError";
import DecimalInput from "../../../../../inputs/DecimalInput";

const Wrapper = styled.div``;

const fields = {
  groups: [
    {
      items: [
        {
          input: { ...inputs.name, disabled: true },
          grid: { xs: 12 },
        },
        {
          input: {
            InputComponent: TextInputWithError,
            name: "billingApprovalSearchDaysSetting",
            label: "Billing Approval Search Days Setting",
            fullWidth: true,
            required: true,
            InputProps: {
              inputComponent: DecimalInput,
            },
            inputProps: {
              decimalScale: 0,
              allowNegative: false,
            },
          },
          grid: { xs: 12 },
        },
      ],
    },
  ],
};

export const EditScope = ({ scope, handleEditScope, closeModalDialog }) => {
  return (
    <FormikForm
      initialValues={scope}
      onSubmit={handleEditScope}
      validationSchema={BWModels.loadSchema("Scope")}
    >
      {({ handleSubmit, ...formikProps }) => (
        <Wrapper>
          <FormGrid fields={fields} {...formikProps} />
          <ActionButtons
            onSend={handleSubmit}
            onCancel={closeModalDialog}
            sendButtonText="Save"
            listeners={[scopeDataComponentId]}
            isModal
          />
        </Wrapper>
      )}
    </FormikForm>
  );
};
