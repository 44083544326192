import React from "react";

import SpecDetailCom from "../../../../models/SpecDetailCom";
import { BWGridAPI, GridTable } from "../../../ui/BWGrid";
import propTypes from "../../../../constants/propTypes";
import { useFlattenedDatacomponent } from "../../../hooks/useDatacomponent";
import PageSection from "../../../layout/PageSection";

const dataComponentId = "relatedSpecsDataComponentId";

const columns = [
  "specDetail.spec.customNumber",
  "specDetail.spec.area.name",
  "specDetail.spec.specCategory.name",
];

const columnOptions = {
  "specDetail.spec.customNumber": { title: "Number", bold: true },
  "specDetail.spec.area.name": { title: "Area" },
  "specDetail.spec.specCategory.name": { title: "Category" },
};

export const RelatedSpecs = ({ spec }) => {
  const dataComponent = useFlattenedDatacomponent(dataComponentId);

  return (
    <PageSection headerText={`${dataComponent.totalRows} Related Specs`}>
      <div style={{ paddingBottom: 24 }} />
      <BWGridAPI
        dataComponent={dataComponent}
        model={SpecDetailCom}
        includes={["specDetail.spec.[area, specCategory]"]}
        apiRoute="spec-detail-coms"
        rootFilters={{
          $where: {
            specId: spec.id,
          },
        }}
      >
        <GridTable columns={columns} columnOptions={columnOptions} />
      </BWGridAPI>
    </PageSection>
  );
};
RelatedSpecs.propTypes = {
  spec: propTypes.spec.isRequired,
};
