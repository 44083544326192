import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _get from "lodash.get";

import { openModalDialog } from "../../../../../actions/layoutActions";
import propTypes from "../../../../../constants/propTypes";
import { getDataComponentFlattenedRequestState } from "../../../../../reducers/dataComponentReducer";
import { getBluechipResourcesByType } from "../../../../../utils/bluechipUtils";
import { Paper } from "../../../../mui/core";
import { LoaderContext } from "../../../../ui/Loader";
import withRenderOnPermissionActive from "../../../../hocs/withRenderOnPermissionActive";
import { BWGridAPI, GridHeader, GridTable } from "../../../../ui/BWGrid";
import Scope from "../../../../../models/Scope";

export const scopeDataComponentId = "dataComponentScope";

export const columns = ["name"];

export const columnOptions = {
  name: { title: "Name", filter: true },
};

const rowMenu = ({ handleOpenEditModal }) => [
  {
    text: "Edit",
    onClick: handleOpenEditModal,
  },
];

export const ScopeContainer = ({
  flattenedDataComponent,
  loading,
  openModalDialog,
}) => {
  const handleOpenEditModal = useCallback(
    scope => {
      openModalDialog("Edit Scope", "EditScope", { scope }, true, true);
    },
    [openModalDialog]
  );

  return (
    <Paper>
      <LoaderContext.Provider value={loading}>
        <BWGridAPI
          dataComponent={flattenedDataComponent}
          model={Scope}
          defaultSorting={[
            {
              columnName: "name",
              direction: "asc",
            },
          ]}
          apiRoute="scopes"
          includes={[]}
        >
          <GridHeader
            headerText={`${flattenedDataComponent.totalRows} Scopes`}
          />
          <GridTable
            columns={columns}
            columnOptions={columnOptions}
            rowMenu={rowMenu({
              handleOpenEditModal,
            })}
          />
        </BWGridAPI>
      </LoaderContext.Provider>
    </Paper>
  );
};

ScopeContainer.propTypes = {
  flattenedDataComponent: propTypes.dataComponent,
  loading: PropTypes.bool,
  openModalDialog: PropTypes.func.isRequired,
};

export const mapStateToProps = state => {
  const flattenedDataComponent = getDataComponentFlattenedRequestState(
    scopeDataComponentId,
    state
  );

  const requirements =
    getBluechipResourcesByType(scopeDataComponentId, state) || [];
  return {
    flattenedDataComponent,
    loading: _get(flattenedDataComponent, "loading") || !requirements,
  };
};

export const mapDispatchToProps = {
  openModalDialog,
};

export default withRenderOnPermissionActive(
  connect(mapStateToProps, mapDispatchToProps)(ScopeContainer),
  ["manage-scope-settings"]
);
