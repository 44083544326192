import React, { useState, useEffect, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _get from "lodash/get";

import TermForm from "./TermForm";
import { closeModalDialog } from "../../../../../../actions/layoutActions";
import propTypes from "../../../../../../constants/propTypes";
import {
  performCreateRequest,
  setReload,
} from "../../../../../../actions/dataComponentActions";

import { getDataComponent } from "../../../../../../reducers/dataComponentReducer";
import { handleRequestError } from "../../../../../../utils/formValidationUtils";
import { processCreateRequestStatus } from "../../../../../../utils/dataComponentUtils";

export const dataComponentId = "TermsGrid";

const processRequestResponse = (
  prevDataComponent,
  dataComponent,
  closeModalDialog,
  setReload,
  formikActions,
  onSuccess
) => {
  processCreateRequestStatus(prevDataComponent, dataComponent, {
    onSuccess: () => {
      closeModalDialog();
      setReload(dataComponentId, true);
      onSuccess();
    },
    onError: error => handleRequestError(error, formikActions),
  });
};

const CreateTermContainer = ({
  dataComponent,
  closeModalDialog,
  setReload,
  term: { id: sourceId, ...term },
  maxPosition,
  performCreateRequest,
  loading,
  params,
  scopeId,
  onSuccess,
}) => {
  const [prevDataComponent, setPrevDataComponent] = useState(dataComponent);
  const [formikActions, setFormikAction] = useState(null);

  useEffect(() => {
    setPrevDataComponent(dataComponent);
  }, [dataComponent]);

  useEffect(() => {
    processRequestResponse(
      prevDataComponent,
      dataComponent,
      closeModalDialog,
      setReload,
      formikActions,
      onSuccess
    );
  });

  const handleCreate = useCallback(
    ({ displayOrder, relation, ...instance }, formikActions) => {
      const data = {
        ...instance,
        displayOrder: Number(displayOrder),
        forRfq: relation.includes("RFQ"),
        forPo: relation.includes("PO"),
      };

      performCreateRequest(dataComponentId, data, params);
      setFormikAction(formikActions);
    },
    [performCreateRequest, params]
  );

  const initialValues = useMemo(() => {
    const { forRfq, forPo, ...cleanTerm } = term;
    const values = {
      ...cleanTerm,
      content: sourceId ? `Copy of ${term.content}` : "",
      displayOrder: maxPosition - 1,
      scopeId,
    };
    values.relation = [];
    if (forRfq) {
      values.relation.push("RFQ");
    }
    if (forPo) {
      values.relation.push("PO");
    }
    return values;
  }, [term, sourceId, maxPosition, scopeId]);

  const isGlobalTerm = !params?.termContext;

  return (
    <TermForm
      initialValues={initialValues}
      maxPosition={maxPosition}
      onSubmit={handleCreate}
      loading={loading}
      isGlobalTerm={isGlobalTerm}
    />
  );
};

CreateTermContainer.defaultProps = {
  term: {
    content: undefined,
    displayOrder: undefined,
  },
};

CreateTermContainer.propTypes = {
  dataComponent: propTypes.dataComponent,
  closeModalDialog: PropTypes.func,
  term: propTypes.term,
  performCreateRequest: PropTypes.func,
  setReload: PropTypes.func,
  maxPosition: PropTypes.number.isRequired,
  loading: PropTypes.bool,
  params: PropTypes.shape({
    termContext: PropTypes.string,
    contextValue: PropTypes.string,
  }),
  scopeId: PropTypes.string,
  onSuccess: PropTypes.func.isRequired,
};

export const mapDispatchToProps = {
  closeModalDialog,
  performCreateRequest,
  setReload,
};

const mapStateToProps = state => {
  const dataComponent = getDataComponent(dataComponentId, state);
  return {
    dataComponent,
    loading: _get(dataComponent, "requestState.create.loading"),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateTermContainer);
