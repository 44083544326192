import React, { useCallback } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import map from "lodash/map";

import useActions from "../../../hooks/useActions";
import { generateAndDownloadReport } from "../../../../actions/reportsActions";

import {
  purchaseOrderPDFSpecsDataComponentId,
  revisionsDataComponentDefinition,
  SpecsGrid,
} from "./SpecsGrid";
import withSelectedRowsAPI from "../../../ui/BWGrid/withSelectedRowsAPI";
import { ActionButtons } from "../../../forms";
import propTypes from "../../../../constants/propTypes";
import { closeModalDialog } from "../../../../actions/layoutActions";

const ActionButtonsWrapper = styled.div`
  padding-top: 24px;
`;

const GridWrapper = styled.div`
  max-height: calc(75vh - 96px);
  overflow-y: auto;
`;

const DownloadPurchaseOrderPDF = ({
  purchaseOrderId,
  revisionId,
  selectedRows,
}) => {
  const actions = useActions({
    generateAndDownloadReport,
    closeModalDialog,
  });

  const handleGeneratePDF = useCallback(
    (skip = false) => () => {
      actions.generateAndDownloadReport({
        format: "pdf",
        purchaseOrderId,
        revisionId,
        templateName: "purchase-order-detail",
        selectedSpecIds: skip ? [] : map(selectedRows, "id"),
      });
      actions.closeModalDialog();
    },
    [actions, purchaseOrderId, revisionId, selectedRows]
  );

  return (
    <div>
      <GridWrapper>
        <SpecsGrid purchaseOrderId={purchaseOrderId} revisionId={revisionId} />
      </GridWrapper>
      <ActionButtonsWrapper>
        <ActionButtons
          onSend={handleGeneratePDF()}
          onSecondary={handleGeneratePDF(true)}
          sendButtonText="Add"
          secondaryButtonText="Skip"
          additionalProps={{
            send: {
              disabled: selectedRows.length === 0,
            },
          }}
          listeners={[
            revisionsDataComponentDefinition.dataComponentId,
            purchaseOrderPDFSpecsDataComponentId,
          ]}
          hideCancelButton
          isModal
        />
      </ActionButtonsWrapper>
    </div>
  );
};
DownloadPurchaseOrderPDF.propTypes = {
  purchaseOrderId: PropTypes.string.isRequired,
  revisionId: PropTypes.string,
  selectedRows: PropTypes.arrayOf(propTypes.spec),
};

export default withSelectedRowsAPI(DownloadPurchaseOrderPDF);
