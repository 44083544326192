import React from "react";
import _without from "lodash/without";
import PropTypes from "prop-types";

import FavoriteStar from "../../../ui/BWGrid/FavoriteStar";
import useAuthUser from "../../../hooks/useAuthUser";
import useActions from "../../../hooks/useActions";
import { updateAuthenticatedUser } from "../../../../actions/authActions";

const FavoriteControl = ({ id }) => {
  const {
    favoriteConsultingCompanyIds = [],
    loadingAuthentication: loading,
  } = useAuthUser();
  const actions = useActions({
    updateAuthenticatedUser,
  });

  const isStarred = favoriteConsultingCompanyIds.includes(id);

  const handleToggle = isStarred => {
    const newFavorites = _without(favoriteConsultingCompanyIds, id);
    if (isStarred) {
      newFavorites.push(id);
    }
    actions.updateAuthenticatedUser({
      favoriteConsultingCompanyIds: newFavorites,
    });
  };

  return (
    <FavoriteStar
      isStarred={isStarred}
      onClick={handleToggle}
      loading={loading}
    />
  );
};

FavoriteControl.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default FavoriteControl;
