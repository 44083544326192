import React from "react";
import styled from "styled-components";
import BWModels from "benjaminwest-models";

import { FormGrid, FormikForm } from "../../../../forms";
import { ActionButtons } from "../../../../forms/ActionButtons";
import { inputs } from "../../../../inputs/inputConfigs";
import { vendorProductDataComponentId } from "../VendorProductsContainer";

const Wrapper = styled.div``;

const fields = {
  groups: [
    {
      items: [
        {
          input: inputs.name,
          grid: { xs: 12 },
        },
        {
          input: inputs.description,
          grid: { xs: 12 },
        },
      ],
    },
  ],
};

export const CreateVendorProduct = ({
  handleCreateVendorProduct,
  closeModalDialog,
}) => {
  return (
    <FormikForm
      initialValues={{ name: "", description: "" }}
      onSubmit={handleCreateVendorProduct}
      validationSchema={BWModels.loadSchema("Product")}
    >
      {({ handleSubmit, values, ...formikProps }) => (
        <Wrapper>
          <FormGrid fields={fields} {...formikProps} />
          <ActionButtons
            onSend={handleSubmit}
            onCancel={closeModalDialog}
            sendButtonText="Create"
            listeners={[vendorProductDataComponentId]}
            isModal
          />
        </Wrapper>
      )}
    </FormikForm>
  );
};
