import React from "react";
import PropTypes from "prop-types";

import SelectWithError from "../SelectWithError";
import TextInputWithError from "../TextInputWithError";
import DatePickerWithError from "../DatePickerWithError";

import { LabeledTextContext } from "../../../withPORevision";
import { LabeledText } from "../LabeledText";
import styled from "styled-components";
import CheckCircle from "@material-ui/icons/CheckCircle";
import useCurrencyFormatter from "../../hooks/useCurrencyFormatter";
import { LabeledPONumber } from "../LabeledPONumber";
import CheckboxWithError from "../CheckboxWithError";

const CheckIconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 24px 0px 12px 0px !important;
`;
export const CheckIcon = styled(({ isChecked, ...props }) => (
  <CheckCircle {...props} />
))`
  margin-right: 8px;
  color: ${props => (props.isChecked ? "#72b327" : "#D8D8D8")};
`;
export const DatePickerWrapper = props => {
  return props.value ? (
    <DatePickerWithError {...props} />
  ) : (
    <TextInputWithError {...props} value="Provide Check # First" />
  );
};

export const withOverwrittedLabeledContext = Component => {
  const WrappedComponent = props => {
    return (
      <LabeledTextContext.Provider value={true}>
        <Component {...props} />
      </LabeledTextContext.Provider>
    );
  };
  return WrappedComponent;
};

export const CustomLabeled = ({ label, value }) => (
  <LabeledText
    label={label}
    texts={[`${Math.floor(value * 100 * 10000000) / 10000000}%`]}
  />
);

CustomLabeled.propTypes = { label: PropTypes.string, value: PropTypes.number };

export const CurrencyLabeled = ({ label, value, InputProps = {} }) => {
  const currencyFormatter = useCurrencyFormatter(InputProps.currency);

  return (
    <LabeledText label={label} texts={[currencyFormatter.format(value)]} />
  );
};

CurrencyLabeled.propTypes = {
  label: PropTypes.string,
  value: PropTypes.number,
  InputProps: PropTypes.object,
};

const BooleanLabeled = ({ label, value }) => {
  return (
    <CheckIconWrapper>
      <CheckIcon isChecked={value} />
      {label}
    </CheckIconWrapper>
  );
};
BooleanLabeled.propTypes = { label: PropTypes.string, value: PropTypes.bool };

const DiscountLabel = ({ label, value }) => {
  if (!value) {
    return "";
  }
  return <LabeledText label={label} texts={[`${value.toFixed(3)}%`]} />;
};
DiscountLabel.propTypes = { label: PropTypes.string, value: PropTypes.number };

export default {
  purchaseOrderId: {
    InputComponent: SelectWithError,
    label: "Select Purchase Order",
    name: "purchaseOrderId",
    dataComponentId: "select-purchase-orders",
    required: true,
    fullWidth: true,
  },
  remitAddressId: {
    InputComponent: SelectWithError,
    label: "Remit Address",
    name: "remitAddressId",
    dataComponentId: "select-remit-addresses",
    fullWidth: true,
  },
  invoiceNumber: {
    InputComponent: TextInputWithError,
    label: "Invoice Number",
    name: "number",
    fullWidth: true,
    required: true,
    autoFocus: true,
  },
  checkNumber: {
    InputComponent: TextInputWithError,
    label: "Check Number",
    name: "checkNumber",
    fullWidth: true,
  },
  invoiceQuantity: {
    InputComponent: TextInputWithError,
    name: "quantity",
    fullWidth: true,
    FloatInputProps: {
      topMargin: "0",
      height: "70px",
      width: "300px",
    },
  },
  paymentDate: {
    InputComponent: withOverwrittedLabeledContext(DatePickerWrapper),
    label: "Payment Date",
    name: "paymentDate",
    fullWidth: true,
  },
  checkRequest: {
    InputComponent: withOverwrittedLabeledContext(TextInputWithError),
    label: "CR#",
    name: "checkRequest",
    fullWidth: true,
  },
  crIssueDate: {
    InputComponent: withOverwrittedLabeledContext(DatePickerWrapper),
    label: "CR# Issue Date",
    name: "crIssueDate",
    fullWidth: true,
  },
  invoicedDate: {
    InputComponent: DatePickerWithError,
    label: "Invoice Date",
    name: "invoicedDate",
    fullWidth: true,
    required: true,
  },
  dueDate: {
    InputComponent: DatePickerWithError,
    label: "Due Date",
    name: "dueDate",
    fullWidth: true,
    required: true,
  },
  poNumber: {
    InputComponent: LabeledPONumber,
    label: "Purchase Order #",
    name: "purchaseOrder",
    fullWidth: true,
  },
  poIssueDate: {
    InputComponent: DatePickerWithError,
    label: "Issue Date",
    name: "purchaseOrder.currentRevision[0].issueDate",
    fullWidth: true,
  },
  poTotal: {
    InputComponent: CurrencyLabeled,
    label: "PO Total",
    name: "purchaseOrder.currentRevision[0].poTotal",
    fullWidth: true,
  },
  projectSalesTaxPercent: {
    name: "purchaseOrder.project.salesTaxPercent",
    InputComponent: CustomLabeled,
    label: "Sales Tax Rate",
    fullWidth: true,
  },
  projectSalesTaxTolerancePercent: {
    name: "purchaseOrder.project.salesTaxTolerancePercent",
    InputComponent: CustomLabeled,
    label: "Sales Tax Tolerance Rate",
    fullWidth: true,
  },
  projectUseTaxRate: {
    name: "purchaseOrder.project.useTaxPercent",
    InputComponent: CustomLabeled,
    label: "Use Tax Rate",
    fullWidth: true,
  },
  isFreightTaxable: {
    name: "purchaseOrder.project.isFreightTaxable",
    InputComponent: BooleanLabeled,
    label: "Vendor Freight Taxable",
    fullWidth: true,
  },
  isInstallTaxable: {
    name: "purchaseOrder.project.isInstallTaxable",
    InputComponent: BooleanLabeled,
    label: "Vendor Install Taxable",
    fullWidth: true,
  },
  projectVendorPaymentTerms: {
    name: "paymentTerm",
    InputComponent: TextInputWithError,
    label: "Vendor Payment Terms:",
    fullWidth: true,
  },
  projectDiscount: {
    name: "discount",
    InputComponent: DiscountLabel,
    label: "Discount:",
    fullWidth: true,
  },
  crRemarks: {
    InputComponent: TextInputWithError,
    label: "Check Request Remarks",
    name: "checkRequestRemarks",
    fullWidth: true,
    multiline: true,
  },
  internalRemarks: {
    InputComponent: TextInputWithError,
    label: "Internal Remarks",
    name: "internalRemarks",
    fullWidth: true,
    multiline: true,
    helperText:
      "Note: The Internal remarks are not eligible to be printed on the check request report.",
  },
  isRefundInvoice: {
    InputComponent: CheckboxWithError,
    label: "Refund",
    name: "isRefundInvoice",
  },
};
