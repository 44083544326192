import { useMemo } from "react";

import SpecDetailCom from "../../../../models/SpecDetailCom";
import { usePerformRetrieveList } from "../../../hooks/usePerformRetrieveList";

export const deleteSpecDetailComsDataComponentId =
  "deleteSpecDetailComsDataComponentId";
const dataComponent = {
  model: SpecDetailCom,
  apiRoute: "spec-detail-coms",
  includes: [],
  dataComponentId: deleteSpecDetailComsDataComponentId,
};
const useListSpecDetailComs = specIdsString => {
  const filters = useMemo(
    () => ({
      rootFilters: {
        $where: {
          specId: {
            $in: specIdsString.split(","),
          },
        },
      },
      pageSize: -1,
    }),
    [specIdsString]
  );
  const memoDataComponent = useMemo(() => dataComponent, []);
  const { data, isLoading } = usePerformRetrieveList(
    memoDataComponent,
    filters
  );

  return {
    comSpecDetailIdSet: new Set(data.map(({ specId }) => String(specId))),
    isLoading,
  };
};

export default useListSpecDetailComs;
