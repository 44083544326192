import React from "react";
import { renderRoutes } from "react-router-config";
import PropTypes from "prop-types";

import Tabs from "../../mui/core/Tabs";
import Tab from "../../mui/core/Tab";
import propTypes from "../../../constants/propTypes";

import {
  Container,
  ContainerContent,
} from "../../layout/PageHeader/PageHeader";
import { Content } from "../../layout/Content";
import NavigationTabs from "../../layout/Navigation/NavigationTabs";
import useUserRole from "../../hooks/useUserRole";
import { roleCan } from "../../../utils/roleUtils";

const getNavigationTabs = role => {
  const navigationTabs = [
    {
      path: "/administrative",
      label: "Administrative",
      permissions: [
        "general-settings-offices",
        "general-settings-reset-password",
        "manage-teams",
        "general-settings-user-roles",
        "manage-scope-settings",
      ],
    },
    {
      path: "/project-detail",
      label: "Project Detail",
      permissions: [
        "general-settings-brands",
        "manage-project-services",
        "manage-project-types",
      ],
    },
    {
      path: "/purchasing",
      label: "Purchasing",
      permissions: [
        "general-settings-requirement-types",
        "general-settings-spec-templates",
        "general-settings-unit-of-measures",
      ],
    },
    {
      path: "/reporting",
      label: "Reporting",
      permissions: ["manage-custom-reports", "manage-query-reports"],
    },
    {
      path: "/terms",
      label: "Terms",
      permissions: ["general-settings-global-terms", "manage-payment-terms"],
    },
    {
      path: "/vendor-detail",
      label: "Vendor Detail",
      permissions: ["manage-vendor-products", "manage-vendor-certifications"],
    },
  ];

  return navigationTabs.filter(({ permissions }) =>
    permissions.some(permission => roleCan(role, permission))
  );
};

const SettingsPage = ({ match, route, history: { push } }) => {
  const role = useUserRole();

  return (
    <div>
      <Container>
        <ContainerContent>
          <NavigationTabs
            location={location}
            navigationTabs={getNavigationTabs(role)}
            match={match}
            push={push}
            Tabs={Tabs}
            Tab={Tab}
          />
        </ContainerContent>
      </Container>
      <Content>{renderRoutes(route.routers, { match })}</Content>
    </div>
  );
};

SettingsPage.propTypes = {
  route: propTypes.route,
  match: propTypes.matchRouter,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export default SettingsPage;
