import React, { useRef } from "react";

import PageSection from "../../../layout/PageSection";
import FormGrid from "../../../forms/FormGrid";
import fields from "./invoiceDataSectionFields";
import Grid from "./Grid";
import { ActionButtons } from "../../../forms";
import { dataComponentId } from "../InvoiceDetailContainer";

const InvoiceDataSection = props => {
  const pageSectionRef = useRef();
  const {
    readOnly,
    setCurrentCellFunc,
    updateInvoice,
    allChangesLocked,
    isLoading,
  } = props;

  return (
    <div id="invoiceDataSection">
      <PageSection pageSectionRef={pageSectionRef} headerText="Invoice Data">
        <FormGrid {...props} fields={fields} />
        <Grid
          invoice={props.values}
          readOnly={readOnly}
          formikProps={props}
          setCurrentCellFunc={setCurrentCellFunc}
          pageSectionRef={pageSectionRef}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            padding: "14px",
          }}
        >
          <ActionButtons
            onSend={updateInvoice}
            sendButtonText="UPDATE"
            disabled={allChangesLocked}
            hideCancelButton={true}
            listeners={[dataComponentId]}
            additionalProps={{
              send: { disableIfProjectClosed: true, isLoading },
            }}
          />
        </div>
      </PageSection>
    </div>
  );
};

InvoiceDataSection.propTypes = {};

export default InvoiceDataSection;
