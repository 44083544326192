import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _get from "lodash.get";

import {
  openModalDialog,
  showSnackNotificationAction,
} from "../../../../actions/layoutActions";
import {
  initDataComponent,
  performRetrieveListRequest,
  performDeleteRequest,
  setReload,
} from "../../../../actions/dataComponentActions";
import propTypes from "../../../../constants/propTypes";
import RequirementType from "./RequirementType";
import {
  getDataComponent,
  getDataComponentFlattenedRequestState,
} from "../../../../reducers/dataComponentReducer";
import { getBluechipResourcesByType } from "../../../../utils/bluechipUtils";
import { Paper } from "../../../mui/core";
import { LoaderContext } from "../../../ui/Loader";
import { useDeleteProcessRequests } from "../../../hooks/useProcessRequest";
import { convertServerErrorToFormikErrors } from "../../../../utils/formValidationUtils";
import withRenderOnPermissionActive from "../../../hocs/withRenderOnPermissionActive";

export const requirementTypeDataComponentId = "dataComponentRequirementType";

export const RequirementTypeContainer = ({
  dataComponent,
  flattenedDataComponent,
  loading,
  performDeleteRequest,
  setReload,
  showSnackNotificationAction,
  openModalDialog,
}) => {
  useDeleteProcessRequests(dataComponent, {
    onSuccess: () => {
      showSnackNotificationAction(
        "The requirement has been deleted successfully"
      );
      setReload(requirementTypeDataComponentId, true);
    },
    onError: error => {
      const convertedError = convertServerErrorToFormikErrors(error);
      showSnackNotificationAction(convertedError.requirements);
    },
  });

  const handleOpenCreateModal = useCallback(() => {
    openModalDialog(
      "Create Requirement Type",
      "CreateRequirementType",
      {},
      true,
      true
    );
  }, [openModalDialog]);

  const handleOpenEditModal = useCallback(
    requirement => {
      openModalDialog(
        "Edit Requirement Type",
        "EditRequirementType",
        { requirement },
        true,
        true
      );
    },
    [openModalDialog]
  );

  const handleDeleteRequirement = ({ id }) => {
    performDeleteRequest(requirementTypeDataComponentId, id);
  };

  return (
    <Paper>
      <LoaderContext.Provider value={loading}>
        <RequirementType
          dataComponent={flattenedDataComponent}
          onOpenCreateModal={handleOpenCreateModal}
          onOpenEditModal={handleOpenEditModal}
          handleDeleteRequirement={handleDeleteRequirement}
        />
      </LoaderContext.Provider>
    </Paper>
  );
};

RequirementTypeContainer.propTypes = {
  dataComponent: propTypes.dataComponent,
  flattenedDataComponent: propTypes.dataComponent,
  loading: PropTypes.bool,
  openModalDialog: PropTypes.func.isRequired,
  performDeleteRequest: PropTypes.func.isRequired,
  setReload: PropTypes.func.isRequired,
  showSnackNotificationAction: PropTypes.func.isRequired,
};

export const mapStateToProps = state => {
  const flattenedDataComponent = getDataComponentFlattenedRequestState(
    requirementTypeDataComponentId,
    state
  );

  const dataComponent = getDataComponent(requirementTypeDataComponentId, state);

  const requirements =
    getBluechipResourcesByType(requirementTypeDataComponentId, state) || [];
  return {
    dataComponent,
    flattenedDataComponent,
    loading: _get(flattenedDataComponent, "loading") || !requirements,
  };
};

export const mapDispatchToProps = {
  initDataComponent,
  performRetrieveListRequest,
  performDeleteRequest,
  setReload,
  showSnackNotificationAction,
  openModalDialog,
};

export default withRenderOnPermissionActive(
  connect(mapStateToProps, mapDispatchToProps)(RequirementTypeContainer),
  ["general-settings-requirement-types"]
);
