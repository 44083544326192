import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _get from "lodash.get";
import AddCircle from "@material-ui/icons/AddCircle";

import {
  closeModalDialog,
  openModalDialog,
  showSnackNotificationAction,
} from "../../../../actions/layoutActions";
import {
  initDataComponent,
  performRetrieveListRequest,
  performDeleteRequest,
  setReload,
} from "../../../../actions/dataComponentActions";
import propTypes from "../../../../constants/propTypes";
import {
  getDataComponent,
  getDataComponentFlattenedRequestState,
} from "../../../../reducers/dataComponentReducer";
import { getBluechipResourcesByType } from "../../../../utils/bluechipUtils";
import { Paper } from "../../../mui/core";
import { LoaderContext } from "../../../ui/Loader";
import { useDeleteProcessRequests } from "../../../hooks/useProcessRequest";
import { convertServerErrorToFormikErrors } from "../../../../utils/formValidationUtils";
import withRenderOnPermissionActive from "../../../hocs/withRenderOnPermissionActive";
import { BWGridAPI, GridHeader, GridTable } from "../../../ui/BWGrid";
import Product from "../../../../models/Product";

export const vendorProductDataComponentId = "dataComponentVendorProduct";

export const columns = ["name", "description"];

export const columnOptions = {
  name: { title: "Name", filter: true },
  description: { title: "Description", filter: true },
};

const rowMenu = ({ handleOpenEditModal, handleDeleteProduct }) => [
  {
    text: "Edit",
    onClick: handleOpenEditModal,
  },
  {
    text: "Delete",
    onClick: handleDeleteProduct,
  },
];

const actions = onOpenCreateModal => [
  {
    text: "Create Product",
    icon: <AddCircle />,
    handler: onOpenCreateModal,
  },
];

export const VendorProductsContainer = ({
  dataComponent,
  flattenedDataComponent,
  loading,
  performDeleteRequest,
  setReload,
  showSnackNotificationAction,
  openModalDialog,
  closeModalDialog,
}) => {
  useDeleteProcessRequests(dataComponent, {
    onSuccess: () => {
      showSnackNotificationAction(
        "The vendor product has been deleted successfully"
      );
      setReload(vendorProductDataComponentId, true);
    },
    onError: error => {
      const convertedError = convertServerErrorToFormikErrors(error);
      showSnackNotificationAction(convertedError["products"]);
    },
  });

  const handleOpenCreateModal = useCallback(() => {
    openModalDialog(
      "Create Vendor Product",
      "CreateVendorProduct",
      {},
      true,
      true
    );
  }, [openModalDialog]);

  const handleOpenEditModal = useCallback(
    vendorProduct => {
      openModalDialog(
        "Edit Vendor Product",
        "EditVendorProduct",
        { vendorProduct },
        true,
        true
      );
    },
    [openModalDialog]
  );

  const handleDeleteProduct = useCallback(
    product => {
      openModalDialog(
        "Delete Vendor Product",
        "GeneralConfirmation",
        {
          title: "Are you sure you want to remove this this vendor product?",
          sendButtonText: "Confirm",
          onSubmit: () => {
            performDeleteRequest(vendorProductDataComponentId, product.id);
            closeModalDialog();
          },
        },
        true,
        true
      );
    },
    [closeModalDialog, openModalDialog, performDeleteRequest]
  );

  return (
    <Paper>
      <LoaderContext.Provider value={loading}>
        <BWGridAPI
          dataComponent={flattenedDataComponent}
          model={Product}
          defaultSorting={[
            {
              columnName: "name",
              direction: "asc",
            },
          ]}
          apiRoute="products"
          includes={[]}
        >
          <GridHeader
            headerText={`${flattenedDataComponent.totalRows} Vendor Products`}
            actions={actions(handleOpenCreateModal)}
          />
          <GridTable
            columns={columns}
            columnOptions={columnOptions}
            rowMenu={rowMenu({
              handleDeleteProduct,
              handleOpenEditModal,
            })}
          />
        </BWGridAPI>
      </LoaderContext.Provider>
    </Paper>
  );
};

VendorProductsContainer.propTypes = {
  dataComponent: propTypes.dataComponent,
  flattenedDataComponent: propTypes.dataComponent,
  loading: PropTypes.bool,
  openModalDialog: PropTypes.func.isRequired,
  performDeleteRequest: PropTypes.func.isRequired,
  setReload: PropTypes.func.isRequired,
  showSnackNotificationAction: PropTypes.func.isRequired,
  closeModalDialog: PropTypes.func.isRequired,
};

export const mapStateToProps = state => {
  const flattenedDataComponent = getDataComponentFlattenedRequestState(
    vendorProductDataComponentId,
    state
  );

  const dataComponent = getDataComponent(vendorProductDataComponentId, state);

  const requirements =
    getBluechipResourcesByType(vendorProductDataComponentId, state) || [];
  return {
    dataComponent,
    flattenedDataComponent,
    loading: _get(flattenedDataComponent, "loading") || !requirements,
  };
};

export const mapDispatchToProps = {
  initDataComponent,
  performRetrieveListRequest,
  performDeleteRequest,
  setReload,
  showSnackNotificationAction,
  openModalDialog,
  closeModalDialog,
};

export default withRenderOnPermissionActive(
  connect(mapStateToProps, mapDispatchToProps)(VendorProductsContainer),
  ["manage-vendor-products"]
);
