import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import {
  closeModalDialog,
  showSnackNotificationAction,
} from "../../../../../actions/layoutActions";
import { CreateVendorProduct } from "./CreateVendorProduct";
import {
  performCreateRequest,
  setReload,
} from "../../../../../actions/dataComponentActions";
import { vendorProductDataComponentId } from "../VendorProductsContainer";
import { useCreateProcessRequests } from "../../../../hooks/useProcessRequest";
import { getDataComponent } from "../../../../../reducers/dataComponentReducer";
import { convertServerErrorToFormikErrors } from "../../../../../utils/formValidationUtils";
import propTypes from "../../../../../constants/propTypes";

const CreateVendorProductContainer = ({
  dataComponent,
  performCreateRequest,
  showSnackNotificationAction,
  setReload,
  closeModalDialog,
}) => {
  useCreateProcessRequests(dataComponent, {
    onSuccess: () => {
      showSnackNotificationAction("Vendor Product created successfully.");
      setReload(vendorProductDataComponentId, true);
      closeModalDialog();
    },
    onError: error => {
      const convertedError = convertServerErrorToFormikErrors(error);
      showSnackNotificationAction(convertedError.name);
    },
  });

  const handleCreateVendorProduct = values => {
    performCreateRequest(vendorProductDataComponentId, values);
  };

  return (
    <CreateVendorProduct
      handleCreateVendorProduct={handleCreateVendorProduct}
      closeModalDialog={closeModalDialog}
    />
  );
};

CreateVendorProductContainer.propTypes = {
  dataComponent: propTypes.dataComponent,
  performCreateRequest: PropTypes.func.isRequired,
  showSnackNotificationAction: PropTypes.func.isRequired,
  setReload: PropTypes.func.isRequired,
  closeModalDialog: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    dataComponent: getDataComponent(vendorProductDataComponentId, state),
  };
};

const mapDispatchToProps = {
  performCreateRequest,
  showSnackNotificationAction,
  setReload,
  closeModalDialog,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateVendorProductContainer);
