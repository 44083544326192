import PropTypes from "prop-types";
import propTypes from "../../../constants/propTypes";

export default {
  purchaseOrderInstance: PropTypes.object,
  dataComponent: propTypes.dataComponent,
  openModalDialog: PropTypes.func.isRequired,
  initDataComponent: PropTypes.func.isRequired,
  fetchPurchaseOrder: PropTypes.func.isRequired,
  populateCountryResources: PropTypes.func.isRequired,
  performRetrieveListRequest: PropTypes.func.isRequired,
  setHeaderTitle: PropTypes.func.isRequired,
  openNotesModal: PropTypes.func.isRequired,
  hasFreightContacts: PropTypes.bool,
  push: PropTypes.func.isRequired,
  purchaseOrderId: PropTypes.string,
  clientId: PropTypes.string,
  loading: PropTypes.bool,
  nextPOId: PropTypes.string,
  lastPOId: PropTypes.string,
  setAutoSaveComponentId: PropTypes.func.isRequired,
  performUpdateRequest: PropTypes.func.isRequired,
  revertFromRevision: PropTypes.func.isRequired,
  revisionValue: PropTypes.shape({
    isActive: PropTypes.bool,
    activities: PropTypes.arrayOf(propTypes.revisionActivities),
  }),
  projectId: PropTypes.string,
};
