import React, { Fragment } from "react";

import useUserRole from "../../../hooks/useUserRole";
import { roleCan } from "../../../../utils/roleUtils";

import ReportTemplates from "../ReportTemplates";
import QueryReports from "../QueryReports";

const Reporting = () => {
  const role = useUserRole();
  return (
    <Fragment>
      {roleCan(role, "manage-custom-reports") ? <ReportTemplates /> : null}
      {roleCan(role, "manage-query-reports") ? <QueryReports /> : null}
    </Fragment>
  );
};

export default Reporting;
