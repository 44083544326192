import React, { useCallback, useState } from "react";
import { IconButton } from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import styled from "styled-components";
import PropTypes from "prop-types";

import { Menu } from "./Menu";
import propTypes from "../../../../../../constants/propTypes";
import { useLabeledText } from "../../../../../inputs/TextInputWithError";

const Wrapper = styled.div`
  position: absolute;
  top: 12px;
  z-index: 1;
  right: 0;
`;

export const RemitAddressMenu = ({ vendor, updateInvoice }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = useCallback(event => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const labeledText = useLabeledText();

  const handleKeyDown = evt => {
    if (evt.key == "Tab") {
      const invoiceQuantityElement = document
        .querySelector("table")
        .querySelector("tbody")
        .querySelector("svg").parentElement;
      invoiceQuantityElement.focus();
      invoiceQuantityElement.click();
    }
  };

  return (
    <Wrapper>
      <IconButton
        onClick={handleClick}
        disabled={labeledText}
        onKeyDown={handleKeyDown}
      >
        <Edit />
      </IconButton>
      {anchorEl && (
        <Menu
          vendor={vendor}
          anchorEl={anchorEl}
          onClose={handleClose}
          updateInvoice={updateInvoice}
        />
      )}
    </Wrapper>
  );
};
RemitAddressMenu.propTypes = {
  vendor: propTypes.vendor,
  updateInvoice: PropTypes.func.isRequired,
};
